.node-page.view-mode-full {


  section {
    &.one-column {

      &.centered {

        .text-container {
          text-align: left;
        }
      }

      figure {
        img {
          max-width: 55em;

          @media only screen and (max-width: $tabletBreakPoint) {
            max-width: 100%;
          }
        }
      }

    }


  }


}

section.infographics {

  padding: $colPadding*2 0;

  .infographics-list {

    list-style: none;
    text-align: center;
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-right: $colPadding;

      @media only screen and (max-width: $tabletBreakPoint) {
        margin: 0 0 $colPadding 0;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }

}
.field-collection-item-field-infographic-fc {

  background: $blue;
  color: $white;
  text-align: center;
  width: 300px;
  min-height: 190px;
  padding: $colPadding;
  display: table;
  vertical-align: middle;

  @media only screen and (max-width: $tabletBreakPoint) {
    width: 100%;
  }

  .wrapper {

    display: table-cell;
    vertical-align: middle;

    .inner {
      margin-bottom: $colPadding/2;

      .prefix {
        display: block;
        @include titleFontLight;
      }

      .value {
        font-size: 3rem;
        @include titleFontBold;
      }

    }

    .text {
      @include condensedFontBold;
    }
  }

}

section.one-column-left-image,
section.one-column {

  &.narrow {
    max-width: 100%;

    .container.with-content {
      max-width: 55em;
    }
  }

}

section.one-column-left-image {

  .text-container {
    h3 {
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
}