* {
  box-sizing: border-box;
}

html {
  font-size: $baseFontSize;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include textFont;
  color: $black;
  line-height: 1.3;
}

section {
  margin-bottom: $colPadding*2;
  position: relative;

  &.centered {
    margin-bottom: $colPadding*2;

    h2 {
      position: relative;
      @include transform(translateY(0));
      margin-bottom: $colPadding;
      text-align: center;
    }
  }

  &.with-title {

    h2 {
      position: relative;
      @include transform(translateY(0));
      margin-bottom: $colPadding;
    }
  }

  h2 {
    font-size: $hugeTextSize;
    @include titleFontBold;
    color: $tertiaryColor;
    margin: 0;
    position: absolute;
    top: 0;
    @include transform(translateY(-110%));


    &.centered {
      position: relative;
      @include transform(translateY(0));
      margin-bottom: $colPadding;
    }
  }

  .node h2 {
    position: relative;
    @include transform(translateY(0));
    margin-bottom: $colPadding/2;
  }

  .node h3.subtitle {
    display: block;
    font-size: $bigTextSize;
    @include titleFontLight;
    margin-bottom: $colPadding;
    color: $darkGrey;
  }

  &.products-services {
    ul {
      margin-bottom: 0;

      li {
        margin-bottom: 0;

        .node {
          margin-bottom: 0;
        }
      }
    }

  }


}


aside {
  h2 {
    position:relative;
    @include transform(translateY(0));
    @include condensedFontBold;
    margin-bottom: $colPadding;


  }

  .block h2 {
    margin-top: 0;
  }
}
article h2 {
  position: relative;
  @include transform(translateY(0));
  margin: $colPadding 0;
  @include condensedFontBold;
  color: $primaryColor;
  font-size: 2.2em;

  &:first-child {
    margin-top: 0;
  }
}
h2 {
  @include condensedFontBold;
  font-size: 3em;
  margin: $colPadding 0;
}

h3 {
  color: $tertiaryColor;
  font-size: $hugeTextSize;
  @include condensedFontBold;
  margin-bottom: $colPadding;
}

h4 {
  color: $tertiaryColor;
  font-size: 1.5rem;
  @include condensedFontBold;
  margin-bottom: $colPadding/4;
}

p {
  margin-bottom: $colPadding;
  font-size: 1.3em;
  line-height: 1.4;
}

a {
  color: $blue;
  @include transition(all 300ms ease-in-out);
  text-decoration: none;

  &:hover {
    color: $secondaryColor;
  }
}

strong {
  @include titleFontBold;
}

em {
 // @include titleFontItalic;
}

ul,ol {
  font-size: 1.3em;
  line-height: 1.4;
  margin-bottom: $colPadding;
}

ol {
  list-style-position: outside;
  list-style-type: decimal;
  margin-left: 1em;
}

/**
 * CUSTOM classes
 */

.featured {
  h2 {


    small {

    }
  }


}

.centered {
  margin: 0 auto;
  text-align: center;
}

.narrow {
  max-width: 55em;
}

.striped {
  background-color: $stripeColor;
  position: relative;
  padding: $colPadding 0;
}

.v-padded {
  padding: $colPadding*2 0;
}

.v-bottom-padded {
  padding-bottom: $colPadding*2;
}
.v-bottom-double-padded {
  padding-bottom: $colPadding*4;
}
.v-top-padded {
  padding-top: $colPadding*2;
}

.v-top-double-padded {
  padding-top: $colPadding*4;
}
.v-margin {
  margin: $colPadding*2 0;
}
.v-top-margin {
  margin-top: $colPadding*5;
}

a.more {
  display: inline-block;

  background: $primaryColor;
  color: $white;
  padding: 6px 12px;

  &:before {
    @extend .iconNext;
    font-size: 12px;
    margin-right: 3px;
  }
}

.more-link,
.read-more {

  a {
    display: inline-block;
    padding: 6px 12px;
    text-transform: uppercase;
    font-size: $normalTextSize;

    .text-container & {
      border-bottom: none;
    }

    &:before {
      @extend .iconNext;
      font-size: 12px;
      margin-right: 5px;
    }
  }

  &.big {
    a {
      padding: $colPadding;
      font-size: 1.3rem;

      @media only screen and (max-width: $tabletBreakPoint) {
        font-size: 1rem;
        padding: $colPadding/2;
      }
    }
  }


  &.link {

    a:before {
      @extend .iconLink;
    }
  }


  &.blue {

    a {
      background: $blue;
      color: $white;

      &:hover {
        background: $yellow;
        color: $black;
      }
    }

  }

  &.yellow {
    a {
      background: $yellow;
      color: $black;

      &:hover {
        background: $orange;
        color: $white;
      }
    }
  }
}

.view {
  .more-link {
    text-align: right;

    a {
      background: $blue;
      color: $white;

      &:hover {
        background: $yellow;
        color: $black;
      }
    }


  }
  .centered > .more-link {
    text-align: center;
    margin: 0 auto;
  }

  .big > .more-link {
    a {
      padding: $colPadding;
      font-size: 1.3rem;

      @media only screen and (max-width: $tabletBreakPoint) {
        font-size: 1rem;
        padding: $colPadding/2;
      }
    }
  }
}

.page-content {
  margin-top: $colPadding*2;
}

.page-view-content {
  margin-top: $colPadding*4;
}

.button {
  a {
    font-size: $tinyTextSize;
    color: $white;
    background: $blue;
    padding: 5px 10px;
    display: block;
    text-transform: uppercase;
    @include condensedFont;
    border-bottom: none !important;

    &:hover {
      background: $primaryColor;
    }
  }

  article & {
    a {
      display: inline-block;
      margin-bottom: $colPadding;
    }
  }
}

figure.bg-image {

  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  img {

    display: none;

    .featured & {
      display: block;
    }

  }
}

.hidden {
  display: none;
}


.select2-drop-active {
  border: none;
}

.panel-page {

  section:last-of-type {
    margin-bottom: 0;
  }
}

h3.subtitle {
  display: block;
  font-size: 1.5rem;
  @include condensedFontLight;
  margin-bottom: $colPadding;
  color: $darkGrey;
}

.buttons-two-cols {
  list-style: none;
  display: table;
  margin: 0 auto !important;

  li {
    display: inline-block;
    margin-right: $colPadding;

    &:last-child {
      margin-right: 0;
    }
  }
}