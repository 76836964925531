.owl-carousel {
  position: relative;
  margin: 0 auto;
  text-align: center;

  .owl-controls {

    .owl-pagination {

      .owl-page {
        display: inline-block;

        span {
          display: block;
          margin-right: $colPadding / 2;
          width:16px;
          height:16px;
          @include border-radius(50%);
          border: 1px solid $orange;
          background: $white;
          @include transition(all 300ms ease-in-out);
        }

        &.active span {
          background: $orange;
        }


      }
    }
  }
}
