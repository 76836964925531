.products-and-services {

  margin-top: $colPadding*3;
  h3 {
    font-size: $hugeTextSize;
    margin-bottom: $colPadding*2;

    small {
      display: block;
      @include condensedFontLight;
      font-size: 1.6rem;
    }
  }
}

.products-list {

  margin-top: $colPadding*2;

  li > div {
    display: inline;
    @include textFont;
    color: $darkGrey;

    span {
      line-height: 1.2em;
      display: block;
      margin-top: $colPadding/2;
      font-size: 1.2rem;
      @include condensedFontBold;
      border-bottom: 1px dotted $darkGrey;
      margin-bottom: $colPadding/4;
      padding-bottom: $colPadding/4;
    }

    &:before {
      width: 128px;
      height: 128px;
      background: $orange;
      @include border-radius(50%);
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: $white;
      font-size: 64px;
      @include transition(all 300ms ease-in-out);

    }

    &:hover {

     //color: $primaryColor;

      &:before {
        background: $yellow !important;
        color: $black;
      }
    }

    &.product-40:before {
      //R&D Consortia Membership
      @extend .iconRDConsortiaMembership;
      background: $colorRDConsortiaMembership;

    }

    &.product-39:before {
      //Sponsored Research
      @extend .iconSponsoredResearch;
      background: $colorSponsoredResearch;
    }

    &.product-38:before {
      //IP Licensing (Technologies)
      @extend .iconIPLicensingTech;
      background: $colorIPLicensingTech;
    }

    &.product-37:before {
      //IP Licensing Genetics
      @extend .iconIPLicensingGenetics;
      background: $colorIPLicensingGenetics;
    }

    &.product-102:before {
      //Consultancy and Advisory Services
      @extend .iconConsultancyAdvisor;
      background: $colorConsultancyAdvisor;
    }

    &.product-100:before {
      //Training and Capacity Building
      @extend .iconTrainingAndCapacityBuilding;
      background: $colorTrainingAndCapacityBuilding;
    }

    &.product-101:before {
      //Product Testing and Analysis
      @extend .iconProductTestingAndAnalysis;
      background: $colorProductTestingAndAnalysis;
    }

    &.product-103:before {
      //Solutions for Smallholders
      @extend .iconSolutions;
      background: $colorSolutions;
    }


    .text-container {
      font-size: 0.8rem;
    }
  }

}