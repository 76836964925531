.gallery {
  img {
    max-width: 100%;
    height: auto;
  }

  figure {
    position: relative;
  }

  figcaption {

    .caption {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: $darkGrey;
      color: $white;
      @include titleFontLight;
      font-size: 14px;
      padding: 6px 12px;
    }

    .credits {
      position: absolute;
      top: 10px;
      left: 10px;
      background: rgba(0,0,0,0.6);
      color: $white;
      padding: 3px 6px;
      @include titleFontLight;
      font-size: 14px;
    }


  }
}
