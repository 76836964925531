header {
  width: 100%;
  background: $white;

  .logo {
    position:relative;
    float:left;
    margin: $colPadding/2 0;
    @include transition(all 300ms ease-in-out);
    will-change: position;

    figure {
      position: relative;

      img {
        display: block;
        background: $white;
        width: 116px;
        border: 0;
        @include transition(all 300ms ease-in-out);
      }

      figcaption {
        display: block;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        margin-left: 130px;
        width: 210px;
        color: $primaryColor;
        text-transform: uppercase;
        @include transition(all 300ms ease-in-out);
        background: url('../images/IRRI-logo-brand-cgiar.svg') no-repeat;
        height: 90px;

        span {
          display: none;
        }
      }
    }
  }
  .main-menu {
    @include transition(margin-left 300ms ease-in-out);
    will-change: margin-left;
  }

  .secondary-menu-wrapper {
    will-change: margin;
  }

  &.fixed {
    position: fixed;
    z-index: 100;
    @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));

    .logo {
      position: absolute;
      margin-top: 7px;
      z-index: 400;

      a {
        background: $white;
        img {
          width: 175px;
          border: 3px solid $white;
          @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));
        }

        figcaption {
          color: transparent;
          left: -9999999px;
        }

      }

      &:before {
        width: 55px;
        height: 18px;
      }


      h1 {
        left: 75px + $colPadding/2;
        font-size: $veryTinyTextSize;
        width: 130px;
      }

    }

    .main-menu {
      margin-left: 60px;
    }

    .secondary-menu-wrapper {
      margin: $colPadding/4;
    }

    nav.secondary-menu {
      ul {
        li {
          a {
            font-size: $tinyTextSize;
          }
        }
      }
    }

    #views-exposed-form-search-search {

      .form-item.form-type-textfield {
        border: none;
        border-bottom: 1px dotted $tertiaryColor;
        height: 22px;
        margin: 0 22px;

        input[type=text] {
          line-height: 21px;
        }

        &:after {
          border: none;
        }
        &:before {
          border:none;
          border-bottom: 1px dotted $tertiaryColor;
          @include border-radius(0);
          left: -27px;
          width: 26px;
          bottom: -1px;
        }
      }
    }
  }



}

.page-title-wrapper {

  background: $primaryColorAlpha;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  time {
    display: block;
    padding-top: $colPadding;
    margin-bottom: -$colPadding/2;
    color: $white;
    @extend .container;
    @extend .with-content;
  }

  h1 {
    color: $white;
    font-size: $hugeTextSize;
    @include textFontBold;
    padding-top: $colPadding;
    padding-bottom: $colPadding;

    @extend .container;
    @extend .with-content;
  }

}

.page-breadcrumb {
  background: $secondaryColor;

  .breadcrumbs {
    @extend .container;
    @extend .with-content;

    li {
      display: inline-block;

      &:last-child:after {
        display: none;
      }

      &:after {
        @extend .iconNext;
        margin: 0 $colPadding/4;
        font-size: 12px;
        color: $white;


      }
      a {
        display: inline-block;
        color: $white;
        text-transform: uppercase;
        font-size: $normalTextSize;
        margin: $colPadding/2 0;
        border-bottom: 1px dotted transparent;

        &:hover {
          border-bottom: 1px dotted $white;
        }
      }

    }

  }
}
