.PSOFOShop {

  max-width: 100%;

  form {

    font-size: $normalTextSize;
    background: $yellow;
    padding: $colPadding;
    margin-bottom: $colPadding*2;
    overflow: auto;
    position: relative;


    /*error*/
    .chpfailedlog {
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
      margin: 0;
      font-size: $tinyTextSize;
      padding: $colPadding/2;
      color: $white;
      background: $red;
      text-transform: uppercase;
      @include border-radius(5px);

      &:before {
        @include triangle(top, $red);
        position: absolute;
        top: -.7em;
        left: .7em;
      }
    }


    #saintro,
    .SA-Intro {
      display: none;
    }

    select {
      background-image: none !important;
    }

    fieldset {


      &:nth-child(2) {
        margin-top: $colPadding;
      }

      legend {
        display: none;
      }

      & > div {
        display: inline-block;
        margin-right: $colPadding;
        position: relative;


      }

      #div-srcparam4 {
        display: block;
        padding: $colPadding 0 $colPadding*3 0;
      }

      #div-srcparam3 {
        margin-right: 0;
        width: 673px;

        input {
          width: 100%;
        }
      }
    }

    .SA-Label,
    label {
      display: block;
      margin-bottom: $colPadding/2;
      font-size: 1.3rem;
      @include condensedFont;
    }

    input[type=password],
    input[type=text] {
      font-size: $normalTextSize;
      padding: $colPadding;
      width: 400px;

      @media only screen and (max-width: $tabletBreakPoint) {
        width: 100%;
        margin-bottom: $colPadding;
      }
    }

    input[type=submit] {
      background: $orange;
      color: $white;
      padding: $colPadding;
      border: none;
      font-size: $normalTextSize;
      @include titleFontBold;
    }

    /* -------------------- Select Box Styles: stackoverflow.com Method */
    /* -------------------- Source: http://stackoverflow.com/a/5809186 */
    select {
      -webkit-appearance: button;
      -webkit-border-radius: 2px;
      -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
      -webkit-padding-end: 20px;
      -webkit-padding-start: 2px;
      -webkit-user-select: none;
      background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
      background-position: 97% center;
      background-repeat: no-repeat;
      border: 1px solid #AAA;
      color: #555;
      font-size: inherit;
      overflow: hidden;
      padding: $colPadding;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  //.radio-as-button

  #div-sagparam3,
  #div-sagparam2,
  #div-srcparam4 {

    input {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px; margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

  }

  #div-sagparam3,
  #div-sagparam2,
  #div-srcitem4 {

    label {
      border: 1px solid $veryLightGrey;
      line-height: 1;
      margin: 0;
      background: $white;
      color: $black;
      display: block;
      padding: $colPadding / 3 $colPadding / 2 !important;
      border-width: 1px 1px 1px 0;

      display: block;
      float: left;
    }

    label:first-of-type {
      border-width: 1px 1px 1px 1px;
      @include border-radius(5px 0 0 5px);
    }

    label:last-child  {
      border-width: 1px 1px 1px 0;
      @include border-radius(0 5px 5px 0);
    }

    label:only-child {
      border-width: 1px 1px 1px 1px;
      @include border-radius(5px);
    }

    input:checked + label,
    label:hover {
      background: $orange;
      color: $white;
    }

  }


  #actions {
    position: absolute;
    bottom: $colPadding;
    right: $colPadding;
    text-align: right;

    #searchagentbutton {
      margin-top: $colPadding/2;

      a {
        color: $black;
        font-size: $tinyTextSize;
        border-bottom: 1px dotted;
        text-transform: uppercase;
      }
    }
  }

  .linkbracket {
    display: none;
  }

  /* Subscribe */
  &#FOsaComp {

    #sag-actions {

      margin-top: $colPadding;
      text-align: right;

      #searchagentbutton {
        margin-top: $colPadding/2;

        a {
          color: $black;
          font-size: $tinyTextSize;
          border-bottom: 1px dotted;
          text-transform: uppercase;
        }
      }

    }
    //.radio-as-button

    #div-sagparam2 {
      width: 343px;
    }

    #div-sagparam3 {
      margin-right: 0;
    }

    #div-sagparam3,
    #div-sagparam2 {

      position: relative;

      label {
        border: 1px solid $veryLightGrey;
        line-height: 1;
        margin: 0;
        background: $white;
        color: $black;
        display: block;
        padding: $colPadding / 3 $colPadding / 2 !important;
        border-width: 1px 1px 1px 0;

        display: block;
        float: left;
      }

      label:first-child {
        background: none;
        display: block;
        border: 0;
        position: absolute;
        top: -32px;
        padding: 0 !important;

        &:hover {
          background: none;
          color: $black;
        }
      }

      label:nth-child(3) {
        border-width: 1px 1px 1px 1px;
        @include border-radius(5px 0 0 5px);
      }

      label:last-child  {
        border-width: 1px 1px 1px 0;
        @include border-radius(0 5px 5px 0);
      }

      label:only-child {
        border-width: 1px 1px 1px 1px;
        @include border-radius(5px);
      }

      input:checked + label,
      label:hover {
        background: $orange;
        color: $white;
      }

    }
  }

  /* login */

  &#FOloginComp {

    #chpintro {
      display: none;
    }

    .chpfailedlog {
      position: relative;
      bottom: 0;
      margin-bottom: $colPadding;

      &:before {
        display: none;
      }
    }

    #chpLogForgot {
      margin-top: $colPadding/2;
      .chpforgot {
        color: $black;
        font-size: $tinyTextSize;
        border-bottom: 1px dotted;
        text-transform: uppercase;
      }
    }


    form {
      margin-bottom: 0;
    }

    fieldset {

      & > div {
        display: block;
        margin-right: 0;
      }

      #chpLogTextUser,
      #chpLogTextPassw {
        margin-bottom: $colPadding;

        input {
          width: 100%;
          position: relative;
        }
      }
    }
  }

  table {

    width: 100%;

    caption {
      @include condensedFontBold;
      margin-bottom: $colPadding;
    }

    .Lst-Header {
      border-bottom: 1px solid $lightGrey;
    }

    .Lst-ResNav {
      border-top: 1px solid $lightGrey;
      text-align: right;
      @include condensedFontBold;
    }

    th {
      @include condensedFontBold;
      padding: $colPadding/2 0;

      a {
        font-size: $bigTextSize;
        color: $black;
      }

    }

    tr:nth-child(odd) td {
      background: $veryLightGrey;
    }

    tr:last-child td {
      background: none;
    }

    td {
      padding: $colPadding/2 0;

      a {
        @include textFontBold;
      }

      em {
        @include textFontLightItalic;
      }
    }
  }



  /* Job Details */

  &#FOjdescComp {

    .button {

      z-index: 100;

      &.top {
        position: absolute;
        top: 5px;
        right: 0;
      }

      &.bottom {
        margin-top: $colPadding/2;
      }

      a {
        position: relative;
        display: inline-block;
        background: $yellow;
        color: $black;
        font-size: $bigTextSize;
        border-bottom: 1px dotted;
        text-transform: uppercase;
        @include transition(all 300ms ease-in-out);

        &:hover {
          background: $orange;
          color: $white;
        }
      }
    }

    #JD-Actions {
      margin-top: $colPadding/2;
      border-bottom: 1px solid $primaryColor;

      & > div {
        display: inline-block;

        a {
          position: relative;
          display: inline-block;
          margin: 0 $colPadding/2 $colPadding/2 0;
          padding: 5px 10px;
          background: $blue;
          font-size: $tinyTextSize;
          color: $white;
          text-transform: uppercase;
          @include transition(background 300ms ease-in-out);

          &:hover {
            background: $yellow;
          }
        }
      }

      .JD-socialNetworks-bottom,
      #JD-ActBack {
        display: none;
      }


    }

    #JD-TopParams {

      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $colPadding 0;
      border: 1px solid $lightGrey;

      & > #JD-salary {
        padding: 0 $colPadding;
      }

      & > div[id^='JD-Param'] {
        padding: 0 $colPadding;
        border-right: 1px dotted $lightGrey;

        &:last-child {
          border: 0;
        }
      }

      #JD-ParamCustom {

        .url {
          border-bottom: 1px dotted $blue;
        }
      }

      h4 {
        @include condensedFontBold;
        font-size: $normalTextSize;
      }
    }

    #JD-AllFields {
      margin: $colPadding 0 0 0;

      & > div[id^='JD-Field'] {
        border-bottom: 1px solid $primaryColor;
      }

      .JD-FieldLabel {
        @include condensedFontBold;
        font-size: $hugeTextSize;
        margin: $colPadding/2 0;
      }

      .JD-FieldText {
        font-size: $normalTextSize;

        span {
          display: inline-block;
          margin-bottom: $colPadding;
        }

        ul,
        p {
          font-size: $normalTextSize;
        }

        ul {
          list-style: disc;

          li {
            margin-left: $colPadding;
          }
        }
      }
    }
  }

  .foot_note {
    margin: $colPadding/2 0;
    padding-top: $colPadding /2;
    border-top: 1px dotted $lightGrey;
    font-size: $tinyTextSize;
    text-align: center;
  }

}

#LstContent,
#FOlstComp {

  table {

    width: 100%;

    caption {
      @include condensedFontBold;
      margin-bottom: $colPadding;
    }

    .Lst-Header {
      border-bottom: 1px solid $lightGrey;
    }

    .Lst-ResNav {
      border-top: 1px solid $lightGrey;
      text-align: right;
      @include condensedFontBold;
    }

    th {
      @include condensedFontBold;
      padding: $colPadding/2 0;

      a {
        font-size: $bigTextSize;
        color: $black;
      }

    }

    tr:nth-child(odd) td {
      background: $veryLightGrey;
    }

    tr:last-child td {
      background: none;
    }

    td {
      padding: $colPadding/2 0;

      a {
        @include textFontBold;
      }

      em {
        @include textFontLightItalic;
      }
    }
  }

  #bottomact {
    margin-top: -25px;

    & > div {
      display: inline-block;
      margin-right: $colPadding/4;

    }

    a {
      font-size: $tinyTextSize;
      color: $white;
      background: $blue;
      padding: 5px 10px;
      display: block;
      text-transform: uppercase;
      @include condensedFont;
      border-bottom: none !important;

      &:hover {
        background: $primaryColor;
      }
    }
  }

  .linkbracket {
    display: none;
  }

}