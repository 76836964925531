.secondary-menu-wrapper {

  float: right;
  margin: 32px $colPadding $colPadding $colPadding;


  nav.secondary-menu {
    display: inline-block;
    margin-right: $colPadding;

    ul {
      margin: 0 $colPadding 0 0;
      display: inline-block;

      li {
        position: relative;
        display: inline-block;
        margin-right: $colPadding;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          color: $tertiaryColor;
          text-decoration: none;
          padding: 12px 0;
          @include titleFontBold;
          font-size: 1rem;
          @include transition(all 300ms ease-in-out);

          &.active,
          &.active-trail,
          &:hover {
            color: $primaryColor;
          }
        }

        ul {
          display: none;

        }

        &:hover {
          ul {
            display: block;
            position: absolute;
            top: calc(33px + .4em);
            z-index: 100;
            background: darken($primaryColor, 10%);
            padding: $colPadding/2;

            &:before {
              @include triangle(top, darken($primaryColor,10%), 0.5em);
              position: absolute;
              z-index: -1;
              top: -0.4em;
              left: .4em;
            }

            li {
              display: block;
              width: 162px;
              margin-right: 0;
              margin-bottom: 0;

              &:last-child a:hover,
              &:last-child a {
                border-bottom: none;
              }


              a {
                display: block;
                position: relative;
                background: darken($primaryColor, 10%);
                border-bottom: 1px solid #005427;
                padding: $colPadding/2 $colPadding/2;
                @include textFontLight;
                font-size: $tinyTextSize;
                color: $white;

                &:hover {
                  @include border-radius(3px);
                  border-bottom: 1px solid transparent;
                }

                &.active,
                &.active-trail,
                &:hover {
                  background: $secondaryColor;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
