@mixin iconFont {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconFont {
  @include iconFont;
}

.iconHome {
  @include iconFont;
  content: '\e91b';
}
.iconMenu {
  @include iconFont;
  content: '\e91a';
}

.iconArrowNext {
  @include iconFont;
  content: '\e904';
}
.iconSearch {
  @include iconFont;
  content: '\e939';
}

.iconPrev {
  @include iconFont;
  content: '\e937';
}
.iconNext {
  @include iconFont;
  content: '\e938';
}

.iconDown {
  @include iconFont;
  content: '\e91e';
}

.iconUp {
  @include iconFont;
  content: '\e91f';
}
.iconMail {
  @include iconFont;
  content: '\e936';
}

.iconMarker {
  @include iconFont;
  content: '\e934';
}

.iconPhone {
  @include iconFont;
  content: '\e905';
}

.iconFax {
  @include iconFont;
  content: '\e906';
}

.iconFacebook {
  @include iconFont;
  content: '\e90c';
}

.iconTwitter {
  @include iconFont;
  content: '\e90b';
}

.iconFlickr {
  @include iconFont;
  content: '\e907';
}

.iconYoutube {
  @include iconFont;
  content: '\e909';
}

.iconLinkedin {
  @include iconFont;
  content: '\e90a';
}

.iconPinterest {
  @include iconFont;
  content: '\e908';
}

.iconCC {
  @include iconFont;
  content: '\e90d';
}

.iconArrowDown {
  @include iconFont;
  content: '\e91c';
}

.iconArrowUp {
  @include iconFont;
  content: '\e91d';
}

.iconLink {
  @include iconFont;
  content: '\e920';
}

.iconChart {
  @include iconFont;
  content: '\e921';
}

.iconQuotesLeft {
  @include iconFont;
  content: '\e926';
}

.iconQuotesRight {
  @include iconFont;
  content: '\e925';
}

.iconSend {
  @include iconFont;
  content: '\f1d8';
}

.iconDownload {
  @include iconFont;
  content: '\e928';
}

.iconClose {
  @include iconFont;
  content: '\e900';
}

.iconClose2 {
  @include iconFont;
  content: '\e901';
}

.iconZoomIn {
  @include iconFont;
  content: '\e903';
}

.iconZoomOut {
  @include iconFont;
  content: '\e902';
}

.iconTheme-1 {
  @include iconFont;
  content: '\e927';
}
.iconTheme-2 {
  @include iconFont;
  content: '\e912';
}
.iconTheme-3 {
  @include iconFont;
  content: '\e915';
}
.iconTheme-4 {
  @include iconFont;
  content: '\e916';
}
.iconTheme-5 {
  @include iconFont;
  content: '\e913';
}
.iconTheme-6 {
  @include iconFont;
  content: '\e910';
}
.iconTheme-7 {
  @include iconFont;
  content: '\e914';
}
.iconTheme-8 {
  @include iconFont;
  content: '\e911';
}
.iconTheme-9 {
  @include iconFont;
  content: '\e90f';
}

.iconSouthEastAsia {
  @include iconFont;
  content: '\e919';
}

.iconEastAsia {
  @include iconFont;
  content: '\e919';
}

.iconSouthAsia {
  @include iconFont;
  content: '\e918';
}

.iconAfrica {
  @include iconFont;
  content: '\e90e';
}

.iconCountryPartners {
  @include iconFont;
  content: '\e935';
}

//knowledge tools

.iconInformationDissemination {
  @include iconFont;
  content: '\e92b';
}

.iconServiceDelivery {
  @include iconFont;
  content: '\e92a';
}

.iconPolicyMaking {
  @include iconFont;
  content: '\f19c';
}

.iconDataCollection {
  @include iconFont;
  content: '\e929';
}

/*
 * Products and Services
 */

.iconTrainingAndCapacityBuilding {
  @include iconFont;
  content: '\e92c';
}

.iconSponsoredResearch {
  @include iconFont;
  content: '\e92d';
}

.iconSolutions {
  @include iconFont;
  content: '\e92e';
}

.iconRDConsortiaMembership {
  @include iconFont;
  content: '\e92f';
}

.iconProductTestingAndAnalysis {
  @include iconFont;
  content: '\e930';
}

.iconIPLicensingTech {
  @include iconFont;
  content: '\e933';
}

.iconIPLicensingGenetics {
  @include iconFont;
  content: '\e932';
}

.iconConsultancyAdvisor {
  @include iconFont;
  content: '\e931';
}