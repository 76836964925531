$tabletBreakPoint: 1023px;
$mobileBreakPoint: 767px;
$rowWidth: 1200px;
$colPadding: 20px;

$baseFontSize: 14px;
$heroTextSize: 3rem;  //42 px
$hugeTextSize: 2rem;  // 28px
$bigTextSize: 1.15rem; // ~ 16px
$normalTextSize: 1rem; // 14px
$tinyTextSize: 0.95rem; // ~ 13px
$veryTinyTextSize: 0.87rem; // ~ 12px

$tabletBreakPoint: 1023px;
$mobileBreakPoint: 767px;
