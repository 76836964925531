a {
  &.lightbox {
    border-bottom: 0;
  }
}

#imageData {
  padding: 0 $colPadding/2 $colPadding/2 $colPadding/2;
  font-size: 1rem;
}

#lightboxImage {
  background: $white;
}

#outerImageContainer {

  overflow: visible;

  #bottomNavClose {
    position: absolute;
    top: -12px;
    right: -12px;
    height: 36px;
    width: 36px;

    background: none;
    @include border-radius(50%);

    &:before {
      @extend .iconClose2;
      font-size: 24px;
      position: relative;
      top: 6px;
      left: 1px;
    }
  }

  #bottomNavZoom {
    background: none;
    background-color: #000;
    color: $white;
    @include border-radius(20px 0 0 0);

    &:before {
      @extend .iconZoomIn;
      font-size: 24px;
      position: relative;
      top: 9px;
      left: 6px;
    }
  }


  #bottomNavZoomOut {
    background: none;
    background-color: #000;
    color: $white;
    @include border-radius(20px 0 0 0);

    &:before {
      @extend .iconZoomOut;
      font-size: 24px;
      position: relative;
      top: 9px;
      left: 6px;
    }
  }
}
