.gsc-control-cse {
font-family: 'Merriweather Sans' !important;
border-color: #FFFFFF;
background-color: #FFFFFF;
}
.gsc-control-cse .gsc-table-result {
font-family: 'Merriweather Sans' !important;
}

.gsc-url-top {
  padding: 0 !important;
}

.cse .gsc-control-cse, .gsc-control-cse {
  padding: 0 !important;
}

.gsc-control-cse,
.gsc-control-cse .gsc-table-result {
  width: 100%;
  font-family: 'Roboto' !important;
  font-size : 1rem !important;
}

.gsc-control-cse .gs-spelling,
.gsc-control-cse .gs-result .gs-title,
.gsc-control-cse .gs-result .gs-title * {
  font-family: 'Merriweather Sans' !important;
  font-weight: 600 !important;
  font-size : $bigTextSize !important;
  line-height: 1.1 !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.gsc-thumbnail-inside {
  padding-left: 0 !important;
}

.gsc-input {
  @include box-shadow(none);
  padding: 5px;
}

.gsc-input-box {
  padding: 10px;    
  height: auto !important;

  &.gsc-input-box-focus {
    @include box-shadow(none !important);
  }
}

td.gsc-search-button {
  width: auto !important;
}

.cse .gsc-search-button-v2,
.gsc-search-button-v2 {
  position: relative;
  height: 45px !important;
  border: none !important;
  @include border-radius(3px !important);

  svg {
    width: 24px;
    height: 24px;
  }
}

a.gcsc-branding-clickable {
  display: inline-flex;
}

.gsc-results .gsc-cursor-box {
  margin: $colPadding*2 !important;
  text-align: center !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-page {
  background: #f2f2f2 !important;
  color: $black !important;
  padding: 7px !important;
  font-family: 'Merriweather Sans' !important;
  font-size : 1rem !important;
  @include transition(all 100ms linear);

  &:hover {
    text-decoration: none !important;
  }

  &:hover,
  &.gsc-cursor-current-page {
    background: $primaryColor !important;
    color: $white !important;
    @include border-radius(2px !important);
  }
}

.gsc-results .gsc-result {
  border: 0 !important;
  &:hover {
    border: 0 !important;
  }
}

.page-thrive-search,
.page-search {
  section.stripe:first-child {
    background: $cream;

    form {
      padding: 0;
    }
  }
}

.gsc-tabsArea {
  height: 40px !important;

  & > div {
    height: 40px !important;
  }
}

.gsc-tabHeader.gsc-tabhActive,
.gsc-tabHeader.gsc-tabhInactive {
  font-size: 1.12rem;
  padding: 12px;
  height: 40px !important;
  line-height: 1em;
  text-transform: none !important;
  font-family: 'Merriweather Sans' !important;
  font-weight: normal !important;

  span {
    text-transform: none !important;
    font-family: 'Merriweather Sans' !important;
    font-weight: normal !important;
  }
}
