.vocabulary-sustainable-development-goals {

  &.view-mode-default {

    img {
      max-width: 100%;
      height: auto;
    }

    figcaption {
      display: none;
    }
  }
}
