.view-mode-full {


  h3 {
    color: $primaryColor;
  }


  figure.full-width {

    img {
      max-width: 100%;
      height: auto;
    }

    margin-bottom: $colPadding;
  }




  &.node-ui-banner {

    position: relative;
    max-width: 800px;
    margin: 0 auto;

    figure {

      font-size:0;

      img {
        max-width: 100%;
        height: auto;

        @media only screen and (max-width: $tabletBreakPoint) {
          min-height: 200px;
          object-fit: cover;
        }
      }
    }

    .wrapper {
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      background: rgba(255,255,255,0.8);

      .content {
        position: absolute;
        top: 50%;
        left:0;
        right:0;
        text-align: center;
        @include transform(translateY(-50%));

      }


      h2 {
        color: $darkGrey;
        font-size: 2.2rem;
        @include text-shadow(3px 3px 5px rgba(255, 255, 255, 0.3));

        @media only screen and (max-width: $tabletBreakPoint) {
          font-size: 1.3rem;
        }
      }

      h3.subtitle {
        @include condensedFontExtraLight;
        font-size: $hugeTextSize;
        color: $black;

        @media only screen and (max-width: $tabletBreakPoint) {
          font-size: 1.3rem;
        }
      }

    }

  }

  &.node-person {

    .header {

      display: table;
      width: 100%;
      vertical-align: top;
      padding-bottom: $colPadding;
      margin-bottom: $colPadding;
      border-bottom: 1px dotted $darkGrey;

      figure {
        display: table-cell;
        width: 15%;
        vertical-align: top;

        img {
          @include border-radius(50%);
          border: 5px solid $primaryColor;
          max-width: 100%;
          height: auto;
        }
      }

      .wrapper {
        display: table-cell;
        width: 85%;
        vertical-align: middle;
        padding-left: $colPadding;

        h2 {
          margin-bottom: $colPadding/4;
        }

        .position {
          @include titleFontLight;
        }
      }
    }

    .addressfield-container-inline {
      .organisation-name {
        @include titleFontBold;
      }
    }

    .country {
      display: block;
      margin-bottom: $colPadding/2;
    }

    .email,
    .phone {
      a {
        font-size: $normalTextSize;
        color: $black;

        &:before {
          position: relative;
          margin-right: 5px;
        }
      }
    }

    .email a:before {
      @extend .iconMail;
      top: 2px;
    }

    .phone a:before {
      @extend .iconPhone;
      top:1px;
    }


  }

  &.field-collection-item-field-testimonials-fc {

    display: table;
    width: 100%;
    margin: $colPadding*2 0;

    @media only screen and (max-width: $tabletBreakPoint) {
      position:relative;
    }

    figure {
      display: table-cell;
      max-width: 25%;
      vertical-align: top;

      @media only screen and (max-width: $tabletBreakPoint) {
        position: absolute;
        top:0;
        left:0;
      }

      img {
        @include border-radius(50%);
        border: 5px solid $primaryColor;
        max-width: 100%;
        height: auto;

        @media only screen and (max-width: $tabletBreakPoint) {
          border: 2px solid $primaryColor;
        }
      }
    }

    .wrapper {
      display: table-cell;
      width: 75%;
      vertical-align: top;
      text-align: left;
      padding-left: $colPadding;

      @media only screen and (max-width: $tabletBreakPoint) {
        width: 100%;
        display: block;
        padding: 0;
      }

      h3 {
        font-size: 1.5rem;
        color: $darkGrey;
        margin-bottom: 0;

        @media only screen and (max-width: $tabletBreakPoint) {
          margin-left: 28%;
          font-size: 1.3rem;
        }
      }

      .position {
        font-size: 1.2rem;
        @include textFontLight;
        color: $darkGrey;
        @media only screen and (max-width: $tabletBreakPoint) {
          margin-left: 28%;
          font-size: 1rem;
        }
      }

      blockquote {
        @include textFontLightItalic;
        font-size: 1.2rem;
        margin-top: $colPadding;

        @media only screen and (max-width: $tabletBreakPoint) {
          font-size: 1em;
          margin-top: 15%;
        }

        &:before,
        &:after {
          color: $orange;
          font-size: 2rem;
          line-height: 1rem;
        }

        &:before {
          @extend .iconQuotesLeft;
          position:relative;
          top:5px;
          margin-right: 5px;
          @media only screen and (max-width: $tabletBreakPoint) {
            float: left;
          }
        }
        &:after {
          @extend .iconQuotesRight ;
          position:relative;
          top:5px;
          margin-left: 5px;
          @media only screen and (max-width: $tabletBreakPoint) {
            display: none;
          }
        }

        p {
          margin: 0;
        }
      }

    }

  }

  &.node-webform {

    form {
      background: $cream;
      .striped & {
        background: $white;
      }

      @media only screen and (max-width: $tabletBreakPoint) {
        width: 100%;
      }

      border: 1px dashed $darkGrey;
      padding: $colPadding;
      @include box-shadow(0px 0px 2px 2px rgba(0,0,0,0.2));
      @include border-radius(10px);
      margin: 0 auto;
      margin-top: $colPadding;
      display: inline-block;

      label {
        display: none;
      }
      textarea,
      input[type="email"],
      input[type="text"] {
        border: 2px solid $darkGrey;
        @include border-radius(calc(10px + 1rem));
        padding: $colPadding/2 $colPadding;
        font-size: 1rem;
        margin-bottom: $colPadding;
        @include transition(all 300ms ease-in-out);

        @media only screen and (max-width: $tabletBreakPoint) {
          width: 100%;
        }

        &::placeholder {
          @include textFontLightItalic;
        }

        &:focus {
          outline: none;
          border: 2px solid $orange;
        }

      }

      textarea {
        padding: $colPadding;
      }

       input[type="submit"] {

         float:right;
         background: $blue;
         color: $white;
         @include border-radius(10px);
         border: none;
         padding: $colPadding/2 $colPadding;
         font-size: 1rem;
         @include titleFontBold;
         text-transform: uppercase;
         @include transition(all 300ms ease-in-out);

         &:after {
           @extend .iconSend;
         }

         &:focus {
           outline: none;
           background: $yellow;
           color: $black;
         }

       }

      select {
        width: 467px;

        @media only screen and (max-width: $tabletBreakPoint) {
          width: 100%;
        }
      }
      .select2-container {
        margin-bottom: $colPadding;
        height: 41px;

        .select2-choice {
          border: 2px solid $darkGrey;
          @include border-radius(calc(10px + 1rem));
          padding: 0 $colPadding;
          height: 41px;
          line-height: 41px;
          font-size: 1rem;
          background-image: none;
          @include box-shadow(none);
          background-color: $white;

          .select2-chosen {
            @include textFontLightItalic;
            text-align: left;
            color: $darkGrey;
            background-color: $white;
            @include box-shadow(none);
          }

          .select2-arrow {
            background: $white;
            border: none;
            height: 37px;
            right: 6px;
            top: 6px;
          }
        }
      }


    }
  }

  &.node-page {
    section:last-child {
      margin-bottom: 0;
    }

    aside {

      h2:first-child {
        margin-top: 0;
      }
    }

    .col-8 {

      section {
        .container.with-content {
          padding: 0;
        }

        &.striped {
          padding: $colPadding;
        }

        &:last-child {
          margin-bottom: $colPadding*2;
        }
      }
    }

    section:only-child {
      margin-bottom: $colPadding*2;
    }





  }

  &.paragraphs-item-two-columns {
    text-align: left;
    width: 100%;
  }

  &.paragraphs-item-banner {

    margin-top: $colPadding*2;
    margin-bottom: $colPadding*2;

    figure {
      font-size: 0;
      overflow: hidden;

      img {
        max-width: 100%;
        height: auto;
        @include transition(all 300ms ease-in-out);
      }
    }

    .text-container {
      background: $orange;
      color: $white;
      text-align: center;
      padding: $colPadding;
      @include transition(all 300ms ease-in-out);

      p {
        margin: 0;
        font-size: 1rem;
        color: $white;
        @include transition(all 300ms ease-in-out);
      }

      h3, h4, h5 {
        text-transform: uppercase;
        color: $white;
        margin: 0 0 $colPadding/2 0;
        font-size: 1.3rem;
        @include transition(all 300ms ease-in-out);
      }

      h3:only-child, h4:only-child, h5:only-child {
        margin: 0;
      }
    }

    a.teaser-anchor {

      figure {
        position: relative;
        overflow: hidden;

      }

      .read-more {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom:0;
        @include transition(all 300ms ease-in-out);

        span {
          position: absolute;
          top: -100px;
          left: 50%;
          display: block;
          background: $blue;
          color: $white;
          padding: 6px 12px;
          font-size: 1rem;
          @include transform(translateX(-50%));
          @include transition(all 300ms ease-in-out);
          @include titleFontLight;
          text-align: center;
          text-transform: uppercase;
        }
      }

      &:hover {

        .read-more {

          background: rgba(255,255,255,0.6);

          span {
            top: calc(100% / 2);
          }

        }

        img {
          @include transform(scale(1.1));
        }

        .text-container {
          background: $yellow;
          color: $black;

          p {
            color: $black;
          }

          h3, h4, h5 {
            color: $black;
          }
        }

      }
    }

  }

  &.node-ui-content {


    &.centered  {

      .text-container {

        color: $darkGrey;

      }
    }

    .read-more {
      a {
        padding: $colPadding;
        @include titleFontBold;
        font-size: 1rem;
      }
    }


  }


  &.paragraphs-item-one-column-left-image {

    display: table;
    width: 100%;

    @media only screen and (max-width: $tabletBreakPoint) {
      display: block;
    }

    figure {
      display: table-cell;
      width:35%;
      vertical-align: top;

      @media only screen and (max-width: $tabletBreakPoint) {
        display:block;
        width: 100%;
        min-height: 30vh;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .wrapper {
      display: table-cell;
      width: 65%;
      vertical-align: top;
      padding: $colPadding;
      background: $cream;

      @media only screen and (max-width: $tabletBreakPoint) {
        width: 100%;
        display: block;
      }

      .striped & {
        background: $white;
      }

      .text-container {
        text-align: left;

        p {
          font-size: 1.2rem;
        }

        h3 {
          color: $darkGrey;
          margin: 0 0 $colPadding 0;
          font-size: 1.5rem;
        }

        h4 {
          @include condensedFontLight;
          font-size: 1.3rem;
          margin: 0 0 $colPadding/2 0;
        }
      }
    }

  }

  &.node-donor {

    position:relative;
    overflow: hidden;

    figure {
      height: 150px;
      background-size: contain;
    }

    .wrapper {
      position: absolute;
      bottom:0;
      left:0;
      right:0;
      @include transform(translateY(100%));
      @include transition(all 300ms ease-in-out);
      background: rgba(255,255,255,0.8);
      padding: $colPadding/4;

      h3 {
        font-size: $tinyTextSize;
        color: $darkGrey;
        @include titleFontBold;
        text-align: center;
        border-bottom: 1px dotted $darkGrey;
        margin-bottom: $colPadding/2;
      }

      p {
        font-size: $tinyTextSize;
        @include titleFontLight;
        color: $darkGrey;
        text-align: center;
      }
    }


    a {
      display: none;


      &.teaser-anchor {
        display: block;

        &:hover {
          .wrapper {
            top: 0;
            @include transform(translateY(0));
          }
        }
      }
    }

    margin-bottom: $colPadding*2;
  }

  &.vocabulary-sustainable-development-goals {

    img {
      max-width: 100%;
      height: auto;
    }

    figcaption {
      display: none;
    }
  }

}

