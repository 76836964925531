.gmap {
  height: 450px;
}
.map-popup-container {
  border-left: 1px dotted $tertiaryColor;

  .map-popup {

    h3 {
      color: $blue;
      @include titleFontBold;
      font-size: $bigTextSize;
      margin-bottom: $colPadding / 2;

      span {

        &.country-11:before {
          @extend .iconSouthAsia;
        }
        &.country-10:before {
          @extend .iconSouthEastAsia;
        }
        &.country-12:before {
          @extend .iconAfrica;
        }
        &.country-13:before {
          @extend .iconCountryPartners;
        }

        &:before {
          font-size: 24px;
          position: relative;
          top: 6px;
          margin-right: $colPadding/4;
        }
      }
    }

    p {
      font-size: $tinyTextSize;
    }

    ul {
      li {
        margin-bottom: $colPadding/4;
        a {
          font-size: $tinyTextSize;
          color: $tertiaryColor;


          &:hover {
            color: $orange;
          }
        }
      }
    }
  }
}

.view-display-id-by_region {

  position:relative;
  padding-top: 5px;

  .item-list {
    background: $yellow;
    overflow: auto;
    margin-top: $colPadding*2;
    padding: $colPadding;

    h3 {
      //position: absolute;
      //top: 0;
      //left: 0;
      //right: 0;
      text-align: center;
      color: $black;

      &:before {
        display: block;
        margin: 0 auto;
        font-size: 128px;
        color: $black;

        .region-10 & {
          @extend .iconSouthEastAsia;
          left:15px;
        }
        .region-11 & {
          @extend .iconSouthAsia;
        }
        .region-12 & {
          @extend .iconAfrica;
          left:15px;
        }
        .region-13 & {
          @extend .iconCountryPartners;
          left:15px;
        }

      }
    }


    ul {
      margin: 0;
      padding: $colPadding/2;
      min-height: 170px;
      background: $orange;

      li {
        display: inline-block;
        width: 48%;
        margin: 0;
        font-size: $normalTextSize;
        text-align: center;

        a {
          display: inline-block;
          color: $white;
          border-bottom: 1px dotted;

          &:hover {
            color: $black;
          }
        }
      }


    }
  }

}

.map-menu {
  position: relative;
  padding: $colPadding*4 0;

  .bgimage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0;
    @include transition(opacity 300ms ease-in-out);

    &.current {
      opacity: 1;
    }
  }

  .container {
    position: relative;
  }

  .overlay {
    background: rgba(255,255,255,0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .node {
    background: rgba(255,255,255,0.8);
    max-width: 100%;
    padding: $colPadding*2;
    margin-bottom:  $colPadding*4;

    h2 {
      color: $black;
    }

    .text-container * {
      color: $black;
    }
  }
}

.view-taxonomy-countries {
  &.view-display-id-panel_pane_1 {



    .wrapper{
      background: $yellow;
      padding: $colPadding;
      @include transition(background 300ms ease-in-out);

      ul {
        background: $orange;
        margin: 0;
        padding: $colPadding 0;
        min-height: 110px;
        @include transition(background 300ms ease-in-out);

        li {
          margin-bottom: $colPadding/4;
          font-size: $tinyTextSize;
          text-align: center;
          @include transition(color 300ms ease-in-out);
          color: $white;
        }
      }

      &:hover {
        background: $blue ;

        h3 span:before,
        h3 span {
          color: $white;
        }

        ul {
          background: $white;

          li {
            color: $black;
          }
        }
      }
    }

    figure {
      display: none;
    }



    h3 {

      span {
        display: block;
        position: relative;
        text-align: center;
        padding-top: 128px;
        color: $black;
        @include transition(color 300ms ease-in-out);

       /* &.country-10:before {
          @extend .iconSouthEastAsia;
        }
        &.country-11:before {
          @extend .iconSouthAsia;
        }
        &.country-12:before {
          @extend .iconAfrica;
        }
        &.country-13:before {
          @extend .iconCountryPartners;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 50%;
          font-size: 128px;
          color: $black;
          @include transform(translateX(-50%));
          @include transition(color 300ms ease-in-out);
        }*/
        img {
          position: absolute;
          top: 0;
          left: 50%;
          @include transform(translateX(-50%));
          width: 180px;
          height: auto;
        }
      }

    }
  }
}

.resources {

  li {
    margin-bottom: $colPadding/2;
  }
  .file {
    img {
      display: none;
    }

    a {
      display: block;
      background:  $yellow;
      padding: 6px 12px;
      @include border-radius(5px);
      color: $white;
      font-size: $normalTextSize;
      text-transform: uppercase;
      position: relative;
      padding-left: 55px;
      color: $black;

      &:before {
        background: $orange;
        @extend .iconDownload;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.4rem;
        height: 31px;
        @include border-radius(5px 0 0 5px);
        color: $white;
        padding: 6px 12px;
        @include transition(background 300ms ease-in-out);
      }

      &:hover {
        color: $white;
        background: $orange;
        padding-left: 45px;

        &:before {

        }
      }
    }
  }

}


