$titleFont: 'Merriweather Sans', sans-serif;
$textFont: 'Roboto', sans-serif;
$condesedFont: 'Roboto Condensed';

@mixin titleFont {
  font-family: $titleFont;
  font-weight: 400;
}

@mixin titleFontLight {
  font-family: $titleFont;
  font-weight: 300;
}

@mixin titleFontBold {
  font-family: $titleFont;
  font-weight: 600;
}

@mixin titleFontItalic {
  font-family: $titleFont;
  font-weight: 300;
  font-style: italic;
}

@mixin textFont {
  font-family: $textFont;
  font-weight: 400;
}

@mixin textFontItalic {
  font-family: $textFont;
  font-weight: 400;
  font-style: italic;
}

@mixin textFontLight {
  font-family: $textFont;
  font-weight: 300;
  @include subPixelAntialias;
}


@mixin textFontLightItalic {
  font-family: $textFont;
  font-weight: 300;
  font-style: italic;
}

@mixin textFontBold {
  font-family: $textFont;
  font-weight: 600;
}

@mixin condensedFont {
  font-family: $condesedFont;
  font-weight: 400;
}

@mixin condensedFontLight {
  font-family: $condesedFont;
  font-weight: 300;
}

@mixin condensedFontExtraLight {
  font-family: $condesedFont;
  font-weight: 200;
}

@mixin condensedFontBold {
  font-family: $condesedFont;
  font-weight: 700;
}
