@charset "UTF-8";


@import "compass/reset";
@import "compass/css3/transform";
@import "compass/css3/opacity";
@import "compass/css3/transition";
@import "compass/css3/box-shadow";
@import "compass/css3/border-radius";
@import "compass/css3/images";
@import "compass/utilities/general/clearfix";
@import "compass/css3/animation";
@import "compass/css3/columns";
@import "compass/css3/filter";
@import "compass/css3/text-shadow";
@import "compass/css3/user-interface";
@import "compass/typography/text/replacement";

@import "base/utilities";

@import "typography/fontfaces";
@import "typography/icons";


@import "base/palette";
@import "base/animations";
@import "base/settings";
@import "base/default";
@import "base/grid";
@import "base/admin";



@import "elements/header";
@import "elements/footer";
@import "elements/secondary-menu";
@import "elements/search";
@import "elements/google_search";
@import "elements/main-menu";
@import "elements/slideshow";
@import "elements/card";
@import "elements/list";
@import "elements/themes-menu";
@import "elements/donors";
@import "elements/map";
@import "elements/video";
@import "elements/article";
@import "elements/text-container";
@import "elements/accordion";
@import "elements/paragraphs";
@import "elements/blocks";
@import "elements/products";
@import "elements/owl-carousel";
@import "elements/ajax-throbber";
@import "elements/knowledge-tools";
@import "elements/sdgs";
@import "elements/jobs";
@import "elements/publications";
@import "elements/lightbox";
@import "elements/ui-spotlight";
@import "elements/gallery";

@import "elements/home";

@import "elements/ar2017-banner";

@import "devices/mobile";
/*
@import "header";
@import "footer";
@import "home";
@import "menu";
@import "topics";
@import "about";
@import "organizers";
*/
