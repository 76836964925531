@-webkit-keyframes wheel-up-down {
  0% {
    bottom: 20px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    bottom: 2px;
    opacity: 0;
  }
}@-moz-keyframes wheel-up-down {
   0% {
     bottom: 20px;
     opacity: 0;
   }
   30% {
     opacity: 1;
   }
   100% {
     bottom: 2px;
     opacity: 0;
   }
 }@keyframes wheel-up-down {
    0% {
      bottom: 20px;
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      bottom: 2px;
      opacity: 0;
    }
  }

@-webkit-keyframes title-pulse {
  0% {
    color: $lightBlue;
  }
  50% {
    color: $green;
  }
  100% {
    color: $lightBlue;
  }
}@-moz-keyframes title-pulse {
   0% {
     color: $lightBlue;
   }
   50% {
     color: $green;
   }
   100% {
     color: $lightBlue;
   }
 }@keyframes title-pulse {
     0% {
       color: $lightBlue;
     }
     50% {
       color: $green;
     }
     100% {
       color: $lightBlue;
     }
  }


@-webkit-keyframes loader {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}@-moz-keyframes loader {
   0% {
     width: 0px;
   }
   100% {
     width: 100%;
   }
 }@keyframes loader {
   0% {
     width: 0px;
   }
   100% {
     width: 100%;
   }
}

@-webkit-keyframes arrow-left {
  0% {
    right: 20px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    right: 2px;
    opacity: 0;
  }
}
@-moz-keyframes arrow-left {
  0% {
    right: 20px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    right: 2px;
    opacity: 0;
  }
}
@keyframes arrow-left {
  0% {
    right: 20px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    right: 2px;
    opacity: 0;
  }
}
