.slideshow {

  margin: 0;

  figure {
    position: relative;
    font-size: 0;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;

      @media only screen and (max-width: $tabletBreakPoint) {
        width: auto;
      }
    }
  }

  &.with-title {
    .slick {
      border: none;
      @include box-shadow(none);
    }


    .node-news.view-mode-carousel {

      position: relative;

      figcaption {
        display: block;
        position: absolute;
        bottom: $colPadding*4;
        left: 0;
        right: 0;
        background: $primaryColorAlpha;
        padding-top: $colPadding;
        padding-bottom: $colPadding;

        @media only screen and (max-width: $tabletBreakPoint) {
          z-index: 200;
          background: none;
        }


        time {
          color: $white;
          font-size: 1.5rem;
          display: block;
          @include condensedFontLight;
          @extend .container;
          @extend .with-content;
        }

        span {
          display: block;
          font-size: $hugeTextSize;
          @include textFontBold;

          @media only screen and (max-width: $tabletBreakPoint) {
            font-size: 1.6rem;
          }

          @extend .container;
          @extend .with-content;

          a {
            color: $white;
          }
        }



      }

    }

  }

  .slick {
    

    .slick-dots {

      position: absolute;
      bottom: 2vh;
      left: 3vw;
      display: inline-block;
      width: auto;

      li {
        background: $white;
        width: 12px;
        height: 12px;
        border: 1px solid white;
        border-radius: 50%;
        float: left;
        margin-right: 5px;
        @include transition(all 300ms ease-in-out);

        &.slick-active {
          background: $primaryColor;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        button {
          display: none;
        }
      }

    }

    .slick-slider {
      margin: 0;
    }
  }

}
.node-page.view-mode-carousel,
.node-ui-slideshow {
  position: relative;
  font-size: 0;

  img {
    max-width: 100vw;
  }

  figcaption {

    &.theme1 {
      position: absolute;
      bottom: 10vh;
      left: 0;
      right: 0;
      padding: $colPadding $colPadding $colPadding 3vw;
      color: $white;
      font-size: $heroTextSize;
      z-index: 3;
      @include text-shadow(0px 2px 3px rgba(0, 0, 0, 0.7));
      background: $primaryColorAlpha2;

      small {
        display: block;
        font-size: 1.5rem;
        @include titleFontLight;

      }

      .read-more {
        display: inline-block;
        margin-top: $colPadding/4;
        padding-top: $colPadding/4;
        border-top: 1px solid #fefefe;

        a {
          display: table;
          margin: 0;
          padding: 5px 0;
          color: #fff;
          font-size: 1.2rem;
          line-height: 1rem;
          text-transform: capitalize;

          &:before {
            display: none;
          }

          &:after {
            @extend .iconArrowNext;
            position: relative;
            top: 2px;
            margin-right: 20px;
            margin-left: 5px;
          }

          &:hover:after {
            margin-right: 0;
            margin-left: 25px;
            animation: 1.6s ease infinite arrow-left;
          }
        }
      }
    }

    &.theme3,
    &.theme2 {
      display: block;
      position: absolute;
      top: 50%;
      right: 3vw;
      @include transform(translateY(-50%));
      padding-right: 20px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      color: $white;
      font-size: 4.5rem;
      text-align: right;
      border-right: 10px solid $white;
      @include text-shadow(0 2px 2px rgba(0,0,0,0.4));

      @include titleFontLight;

      strong {
        @include titleFontBold;
      }

      em {
        @include titleFontItalic;
      }

      .read-more {
        a {
          color: $white;
        }
      }
    }

    &.theme3 {
      color: #414141;
      border-color: #414141;
    //  border-right: none;
    //  border-left:10px solid $black;
    //  right: auto;
    //  left: 2em;
    //  text-align: left;
    //  padding-right: 0;
    //  padding-left: 10px;

      .read-more {
        a {
          color: #414141;
        }
      }
    }
  }
}
