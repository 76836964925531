$green: #007837;
$lightGreen: #20ae50;
$black: #222;
$darkGrey: #5c676f;
$lightBlue: #8bbce5;
$blue: #428f9f;
$white: #fff;
$cream: #eeeae7;
$red: #da383f;
$grey: #757575;
$lightGrey: #ababab;
$veryLightGrey: #efefef;

$menuGrey: #e3e2e2;

$primaryColor: $green;
$primaryColorAlpha: rgba(0, 120, 55, 0.8);
$primaryColorAlpha2: rgba(0, 120, 55, 0.6);
//$primaryColorAlpha: rgba(238, 91, 49, 0.8);

$secondaryColor: $lightGreen;
//$secondaryColorAlpha: rgba(32, 174, 80, 0.8);

$secondaryColorAlpha: rgba(32, 174, 80, 0.8);
$themes-icon-green: #4eca4e;

$tertiaryColor: $darkGrey;

$stripeColor: $cream;

$orange: #ee9f33;
$yellow: #f2cf4c;


$gold: rgba(255,223,0,0.3); //#FFDF00;
$silver: rgba(211,211,211,0.3); //#D3D3D3;
$bronze: rgba(201,174,93,0.3); //#C9AE5D;


$colorRDConsortiaMembership: #3399ff;
$colorSponsoredResearch: #993399;
$colorIPLicensingTech: #666666;
$colorIPLicensingGenetics: #33cc00;
$colorConsultancyAdvisor: #ffcc00;
$colorTrainingAndCapacityBuilding: #006666;
$colorProductTestingAndAnalysis: #ff9933;
$colorSolutions: #ff3366;


$colorTwitter: #00acee;
