.node-ui-spotlight {
  &.view-mode-full {
    figure {
      position: relative;
      img {
        width: 100%;
      }
      .type {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primaryColor;
        color: $white;
        padding: 5px;
      }
    }
    .content {
      padding: 20px;
      text-align: left;
      .date {
        font-size: 16px;
        color: $grey;
        @include textFontLight;
      }
      h3 {
        margin-bottom: 5px;
      }
      .source {
        margin: 5px 0;
        color: $grey;
        font-size: 16px;
        @include textFontBold;
      }
      .read-more {
        margin-top: 15px;
        a {
          position: relative;
          background-color: $yellow;
          color: $black;

          &:before {
            display: none;
          }

          &:after {
            @extend .iconNext;
            font-size: 12px;
            margin-left: 5px;
          }

          @include transition(all 300ms ease-in-out);

          &:hover {
            background-color: $orange;
          }
        }
      }
    }
  }
}

.ui-spotlight-widget {
  .slick--skin--boxed-carousel .slide__content {
    margin: 0;
  }
  .slick__arrow {

    .slick-prev,
    .slick-next {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));

      &:before {
        display: block;
        font-size: 36px;
        width: 36px;
        color: $tertiaryColor;
        @include transition(all 300ms ease-in-out);

      }

      &:hover:before {
        color: $primaryColor;
      }
    }

    .slick-prev {
      left: 0;

      &:before {
        @extend .iconPrev;
      }
    }

    .slick-next {
      right: 0;
      &:before {
        @extend .iconNext;
      }
    }
  }
}
