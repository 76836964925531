.ui-accordion {

  margin-bottom: $colPadding;
  h3 {
    background: $orange;
    border: none;
    @include border-radius(0);

    a {
      background: transparent;
    }

    .theme-two & {
      background: $green;
      color: $white;
    }

    &.ui-accordion-header-active {
      background-color: $yellow;

      .ui-icon:before {
        color: $black;
        @include transform(rotate(90deg));
      }

      a {
        background: transparent;
      }

      .theme-two & {
        background: $lightGreen;

        a {
          background: transparent;
          color: $white;
        }

        .ui-icon:before {
          color: $white;
        }
      }
    }

    .ui-icon {
      background: none;
      &:before {
        @extend .iconNext;
        font-size: 12px;
        width: 16px;
        height: 16px;
        display: block;
        color: $white;
        text-indent:0;
        position:relative;
        top:2px;
        @include transition(all 300ms ease-in-out);
      }
    }
  }

  .ui-state-default a,
  .ui-state-default a:link,
  .ui-state-default a:visited {
    color: $white;
    @include titleFontBold;
  }

  .ui-state-active a,
  .ui-state-active a:link,
  .ui-state-active a:visited {
    color: $black;
    background: $yellow;
  }

  .ui-accordion-content {
    @extend .text-container;
    @include textFont;
    background: $cream;
    font-size: $normalTextSize;
    border: none;
  }
}