.knowledge-tools-banners {

  li {

    text-align: center;

    > div {

      &:before {
        background: $orange;
        color: $white;
        @include border-radius(50%);
        font-size: 96px;
        margin-bottom: $colPadding;
        display: inline-block;
        padding: 50px;
        width: 196px;
      }

      &.information-dissemination:before {
        @extend .iconInformationDissemination;
      }

      &.service-delivery:before {
        @extend .iconServiceDelivery;
      }

      &.policy-making:before {
        @extend .iconPolicyMaking;
      }

      &.data-collection:before {
        @extend .iconDataCollection;
      }

    }

    h4 {
      border-top: 1px dotted $grey;
      padding-top: $colPadding/2;
    }

    p {
      font-size: $tinyTextSize;
    }
  }

}

.view-id-taxonomy_tools.view-display-id-panel_pane_1 {


  background-color: $cream;
  padding: $colPadding/2;
  display: table;
  vertical-align: top;
  width: 100%;

  .view-filters {
    display:table-cell;
    vertical-align: top;
    width: 30%;
    padding: $colPadding;

    .views-exposed-widget {
      & > label {
        @include titleFontBold;
        color: $darkGrey;
        font-size: 1.5rem;
        margin-bottom: $colPadding;
        display: block;
      }
    }

    .form-submit {
      display: none;
    }

    .form-item-activity {


      margin-bottom: 40px;
      display: table;


      .bef-checkboxes {

        & > .form-item {
          position: relative;
          margin-bottom: $colPadding/2;

          span {
            margin-left: 30px;
            display: block;
            font-size: 1.1rem;
            @include titleFontLight;
            color: $grey;
          }
        }

        input[type="checkbox"] {
          @include transition(all 300ms ease-in-out);
          display: none;
        }

        input[type="checkbox"] + label {
          position: absolute;
          width: 20px;
          height: 20px;
          border: 2px solid $lightGrey;
          border-radius: 3px;
          top: calc(50% - 11px);
          left: 0;
          //transform: translate(-50%, -50%);
          @include transition(all 300ms ease-in-out);
          @include box-shadow(rgba(blue, 0.4) 0 0 0, rgba(green, 0.2) 0 0 3px 1px inset);

          text-indent: -9999px;
          white-space: nowrap;
          user-select: none;
          line-height: 24px;
          background: $white;


          &:after {
            content: "";
            position: absolute;
            width: 0px;
            height: 10px;
            border-bottom: 5px solid $grey;
            border-left: 5px solid $grey;
            top: 35%;
            left: 50%;
            transform-origin: bottom left;
            transform: rotate(-45deg);
            opacity: 0;
            @include transition(all 300ms ease-in-out);
          }
        }

        input[type="checkbox"]:checked + label {
          border: 2px solid $grey;

          &:after {
            opacity: 1;
            width: 22px;
          }
        }
      }
    }
  }

  .view-empty,
  .view-content {
    display:table-cell;
    vertical-align: top;
    width: 70%;
    background: $white;
    padding: $colPadding $colPadding*2;
    position: relative;

    &:before {
      @include triangle(left, $white, 2em);
      position: absolute;
      top: 0;
      left: -2em;
    }
  }

  .view-empty {
    p {
      @include titleFontLight;
      font-size: 1.1rem;
      color: $grey;
      padding: $colPadding/2;
    }
  }



}