/* mobile menu */

#mobile-menu-trigger {
  display: none;
  float: right;
  margin: 20px 10px 0 0;

  &:before {
    @extend .iconMenu;
    font-size: 32px;
    color: $tertiaryColor;
    border: 1px solid $tertiaryColor;
    padding: 5px;
    @include border-radius(5px);
  }
}

.mobile-only {

  display: none;

}

.mobile-topbar {
  display: none;
  background: $green;

  ul.menu {
    margin:0;
    padding: $colPadding/4 $colPadding/2;

    li {
      display: inline-block;
      margin-right: 2px;
      a {
        position: relative;
        text-indent: -9999px;
        display: inline-block;
        width: 16px;
        color: $white;

        &:hover {
          text-decoration: none;
        }

        &:before {
          font-size: 16px;
          display: inline-block;
          position: absolute;
          left: 50%;
          top: 50%;
          text-indent: 0;
          @include transform(translate(-50%, -50%));
        }

        &.facebook:before {
          @extend .iconFacebook;
        }
        &.twitter:before {
          @extend .iconTwitter;
        }
        &.flickr:before {
          @extend .iconFlickr;
        }
        &.youtube:before {
          @extend .iconYoutube;
        }
        &.linkedin:before {
          @extend .iconLinkedin;
        }
        &.pinterest:before {
          @extend .iconPinterest;
        }
      }
    }
  }
}

.page-primary-submenu {
  .toggle {
    display: none;
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ .menu-name-main-menu {
      height: auto /* Full height when more is toggled */;
    }

    /* Flip the arrow when toggled */
    &:checked ~ .menu-name-main-menu .submenu-navigation-toggle .navigation-link:after {
      @include triangle(top, $white, 5px);
      @include triangle(bottom, transparent, 5px);
      top:0;
    }
  }

  .submenu-navigation-toggle {
    display: none;
    flex-shrink: 0 /* Stops more button collapsing */;

    .navigation-link {
      position:relative;
      padding-right: 30px;
      color: $white;
      line-height: 30px;

      &:after {
        content: '';
        position: absolute;
        top: 5px;
        right: 10px;
        width: 0;
        height: 0;
        @include triangle(bottom, $white, 5px);
      }
    }
  }

  .menu li {
    @media screen and (max-width: 400px) {
      .navigation__item {
        flex-basis: 100% /* Stops uneven wrapping */;
      }
    }
  }


}

@media only screen and (max-width: $tabletBreakPoint) {

  /* Jobs */
  .PSOFOShop {

    form {

      fieldset {

        #div-srcparam3 {
          width: 497px;
        }


        #div-sagparam1 {
          input {
            width: 272px;
          }
        }

      }
    }

    /* Subscribe */
    &#FOsaComp {

      #div-sagparam2 {
        width: 295px;
      }
    }
  }



  .page-node.node-type-news {

    .slideshow {
      figcaption {
        display: none;
      }
    }
  }


  header {

    .secondary-menu-wrapper {

      margin-right: 0;

      #views-exposed-form-search-search {
        display: none;
      }

      nav.secondary-menu {
        margin: 0;

        ul {


          li {
            margin-right: $colPadding/2;

            a {
              @include condensedFontBold;
              font-size: 1rem;
            }

            ul {
              display: none;
            }
          }
        }
      }
    }

    .main-menu-wrapper {
      nav.main-menu {
        ul li a {
          @include condensedFontBold;
          padding: 8px;

          ul {
            display: none;
          }
        }
      }
    }


  }

  .front .view-mode-list.node-news .wrapper .content {
    display: none;
  }

  .slick-initialized ~ .slick__arrow {
    display: none;
  }

  #search-box {
    display: none;
  }

  .mobile-only {

    display: block;

    &.mobile-search-trigger {
      display: inline-block;
    }
  }

  .search-bar {

    form {
      .views-exposed-widget {
        width: 100%;
        margin: 0 0 20px 0;

        input[type="text"] {
          margin-bottom: 0;
        }

      }
    }

  }

  .page-primary-submenu .menu {
    li {

      margin: 0 $colPadding/2 $colPadding/2 0;

      a {
        @include condensedFont;
        padding: 6px 12px;
      }
    }

  }

  article.view-mode-teaser.vocabulary-outcome-theme > a > div .content {
    display: none;
  }

  aside h2 {
    font-size: 1.5rem;
  }

  .view-mode-full.field-collection-item-field-testimonials-fc {
    figure {
      max-width: 20%;
    }
    .wrapper {
      display: table-cell;
      max-width: 80%;

      .position,
      h3 {
        margin-left: 25%;
      }

      blockquote {
        margin-left: 25%;
        margin-top: $colPadding;
      }
    }
  }

  .knowledge-tools-banners li > div:before {
    width: 120px;
    font-size: 48px;
    padding: 36px;
  }

  .view-display-id-by_region {

    .item-list {
      margin: 0;

      h3 {
        @include condensedFontBold;
        font-size: 1.4rem;
        position: relative;
        top: 20px;
        line-height: 1;
      }

      ul {
        margin: 0;
        padding: $colPadding 0;
        text-align: center;
        border: 0;
        min-height: 215px;

        &:before {
          display: none;
        }
      }
    }
  }

  .slideshow {

    figure.node {

      img {
        object-fit: cover;
        max-width: 100%;
      }
    }
  }

}

@media only screen and (max-width: $mobileBreakPoint) {

  /* Jobs */
  .PSOFOShop {

    margin-bottom: $colPadding;

    form {

      fieldset {

        & > div {
          display: block;
          margin-bottom: $colPadding / 2;
        }


        #div-srcparam1,
        #div-srcparam2,
        #div-srcparam3 {
          width: 100%;
          margin-right: 0;
        }

        #div-srcparam3 {
          input {
            margin-bottom: 0;
          }
        }

        #div-srcparam4 {
          padding-top: 0;
        }

        select {
          width: 100% !important;
        }



      }



    }

    #TableJobs {
      /* Force table to not be like tables anymore */
      table, thead, tbody, th, td, tr, caption {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      .Lst-Header {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr:last-child {
        td:before {
          display: none;
        }
      }

      tr:nth-of-type(odd) {
        td {
          border-bottom: 1px solid $lightGrey;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid $lightGrey;
        position: relative;
        padding: 2em $colPadding/2 $colPadding/2 $colPadding/2;

        &:last-child {
          border-bottom: 0;
        }
      }

      td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        right: $colPadding/2;
        left: $colPadding/2;
        width: 100%;
        white-space: nowrap;
        @include condensedFontBold;
        text-transform: uppercase;
      }

      /*
      Label the data
      */
      td:nth-of-type(1):before {
        content: "Title";
      }

      td:nth-of-type(2):before {
        content: "Location";
      }

      td:nth-of-type(3):before {
        content: "Mode of employment";
      }

      td:nth-of-type(4):before {
        content: "Closing Date";
      }
    }

    &#FOjdescComp #JD-Actions {

      & > div {

        a {

        }
      }
    }

    #FOlstComp #bottomact {
      margin-top: $colPadding/2;
    }

    /* Subscribe */
    &#FOsaComp {


      #div-sagparam1,
      #div-sagparam2 {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
      }

      .SAgLogSet {
        margin: 0;
      }

      #div-sagparam1 {
        input {
          width: 100%;
        }
      }

      #div-sagparam3,
      #div-sagparam2 {

        overflow: auto;

        label {
          font-size: 1rem;
        }

        label:first-child {
          position: relative;
          top: 0;
          float: none;
          clear: both;
          margin-bottom: $colPadding/2;
          font-size: 1.3rem;
        }
      }

      #div-sagparam3 {
        label:first-child {
          margin-top: $colPadding/2;
        }
      }

    }

    /* Login */
    &#FOloginComp {

      fieldset {
        #chpLogTextUser,
        #chpLogTextPassw {
          margin-bottom: 0;
        }


      }
    }

    /* Job Details */

    &#FOjdescComp {
      .button {

        &.top {
          position: relative;
          top: auto;
          right: auto;
          margin: 0 0 $colPadding/2 0;
        }
      }

      #JD-TopParams {

        display: block;
        padding: 0;
        border: 1px solid $lightGrey;

        & > #JD-salary {
          margin: 0 $colPadding/2;
          padding: $colPadding/2 0;
        }

        & > div[id^='JD-Param'] {
          margin: 0 $colPadding/2;
          padding: $colPadding/2 0;
          border-right: 0;
          border-bottom: 1px dotted $lightGrey;

        }

        h4 {
          @include condensedFontBold;
          font-size: $normalTextSize;
        }
      }
    }
  }


  .page-node.node-type-news {

    .slideshow {
      figcaption {
        display: none;
      }
    }
  }

  #mobile-menu-trigger {
    display: block;
  }

  header {

    position: relative;

    .mobile-topbar {
      display: block;
    }

    .mobile-search-trigger {
      position: absolute;
      top: $colPadding/4;
      right: $colPadding/4;

      &:before {
        color: $white;
      }
    }

    .topbar-wrapper {

      overflow: auto;
      border-top: 10px solid $lightGreen;
      border-bottom: 10px solid $green;

      .container.with-content {
        padding:0;
      }

      .secondary-menu-wrapper {
        display: none;
      }

      .logo {
        margin: 10px;

        figure {
          font-size: 0;
        }

        figcaption {
          font-size: 0.8rem;
          max-width: 160px;
          margin-left: 130px;
        }
      }
    }

    .main-menu-wrapper {
      display: none;
      padding-bottom: 10px;

      .container.with-content {
        padding: 0 $colPadding/2;
      }

      nav.main-menu {
        ul {
          overflow: auto;
          & > li {
            display: block;
            background: $lightGreen;
            margin-bottom: $colPadding/2;

            a {
              font-size: 1rem;
              padding: 10px;

              &:hover {
                background: inherit;
              }

              &.active {
                background: #055429;
              }
            }

            &:first-child a {
              width: 100%;
            }

            &.themes {

              ul li {
                display: block;
                width: 100%;


                &:nth-last-child(2) {
                  border-bottom: 1px dotted $white;
                }

                &:nth-child(odd) {
                  border-right: none;
                }


                a {
                  text-align: left;
                  position: relative;
                  margin-left: 50px;

                  &:before {
                    font-size: 24px;
                    @include iconFont;
                    position: absolute;
                    left: -30px;
                    top: 50%;
                    @include transform(translateY(-50%));
                  }

                  &.theme-1:before {
                    content: '\e927';
                  }
                  &.theme-2:before {
                    content: '\e912';
                  }
                  &.theme-3:before {
                    content: '\e915';
                  }
                  &.theme-4:before {
                    content: '\e916';
                  }
                  &.theme-5:before {
                    content: '\e913';
                  }
                  &.theme-6:before {
                    content: '\e910';
                  }
                  &.theme-7:before {
                    content: '\e914';
                  }
                  &.theme-8:before {
                    content: '\e911';
                  }
                  &.theme-9:before {
                    content: '\e90f';
                  }
                }
              }

            }

            ul {
              position: relative;
              top: auto;
              display: block;
              padding: 0;
              background: rgba(255,255,255, 0.3);

              &:before {
                display: none;
              }

              li {
                background: transparent;
                width: 50%;
                display: inline-block;
                float:left;
                margin-bottom:0;
                border-bottom: 1px dotted $white;

                ul {
                  display: none;
                }

                a {
                  padding: $colPadding $colPadding/2 !important;
                  border-bottom: 0;
                  @include condensedFont;
                  font-size: 0.9rem;
                  text-align: center;

                }

                &:nth-child(odd) {
                  border-right: 1px dotted $white;
                }

                &:last-child {
                  border-bottom: none;
                }

                &:nth-last-child(2) {
                  border-bottom: none;
                }

                &:nth-child(2n +1) {
                  clear: left;
                }
              }
            }

            .home {
              &:before {
                display: none;
              }
              text-indent: unset;
            }
          }
        }
      }

      .secondary-menu-wrapper {
        float: none;
        margin: $colPadding/2;
        //background: $white;

        #views-exposed-form-search-search {
          display: none;
        }

        nav.secondary-menu {
          margin:0;
          display: block;

          ul {
            margin:0;
            display: block;
            overflow: auto;
            padding: $colPadding/2 0;
            background: $white;

            li {
              float: left;
              width: 33.3%;
              margin: 0;
              display: block;
              padding: $colPadding;
              border-right: 1px dotted $grey;
              text-align: center;

              &:nth-child(3n) {
                border-right: none;
              }

              &:nth-child(3n+1) {
                clear: left;
              }

              a {
                @include condensedFontLight;
                font-size: 0.9rem;
                line-height: 1.2em;
                text-align: center;
              }

              ul {
                display: none;
              }
            }
          }
        }
      }
    }

  }

  footer {
    padding-top: $colPadding;

    .logo {
      width: 220px;
      height: 240px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    .col, .col:last-child {
      border-right: none;
      border-bottom: 1px dotted $white;
      padding-top: $colPadding/2;
      padding-bottom: $colPadding/2;
      margin: $colPadding/2 0;
    }

    .legal {
      padding: $colPadding/2;
      text-align: center;
    }
  }


  .page-primary-submenu {

    .menu-name-main-menu {
      display: flex;
      max-width: 767px;
      height: 30px /* Collapsed navigation height */;
      margin: 0 auto;
      overflow: hidden;
    }

    .submenu-navigation-toggle {
      display: block;
    }

    .toggle {
      display: block;
    }

    .menu {

      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 10px;
        margin-bottom: 10px;
        line-height: 22px;
        cursor: pointer;

        &:last-child {
          margin:0;
        }


        a {
          padding: 3px 6px;
          font-size: 1rem;
        }

      }

    }
  }
  .page-title-wrapper h1 {
    font-size: 1.5rem;
  }
  .slideshow {
    min-height: 300px;

    figure.taxonomy-term,
    figure.node {

      &:after {
        content: '';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: rgba(0, 0, 0, 0.4);
      }
      img {
        object-fit: cover;
        min-height: 350px;
        max-width: 100%;
      }

      figcaption {
        font-size: 2rem;
        line-height: 1.1em;
        top: 50%;
        left: $colPadding/2;
        right: $colPadding/2;
        @include transform(translateY(-50%));

        small {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin-top: $colPadding/4;
        }
      }
    }
  }

  .v-padded {
    padding: $colPadding 0;
  }

  section h2 {
    font-size: 1.6rem;
  }

  .themes-menu {
    margin-top: $colPadding*2;

    .themes {

      margin: $colPadding*2 0;

      ul li {
        margin:0;
        padding:0;
        max-width: 100%;
        display: block;
        text-align: left;

        a {
          position: relative;
          text-align: left;

          &:hover {
            span {
              margin-top:0;
            }
          }

          i {
            width:48px;
            height: 48px;
            position: absolute;
            left: 0;
            top: 0;

            &:before {
              font-size: 32px;
              top:8px;
              left:8px;
            }
          }

          span {
            margin: 0 0 0 64px;
            font-size: 1rem;
          }
        }
      }

    }

  }


  .map-wrapper {
    display: none;
  }
  .map-popup-container {
    border: none;
    .map-popup {
      h3 {
        span {
          position: relative;
          width: 100%;
          background: $blue;
          display: block;
          padding: $colPadding/2;
          color: white;
          @include border-radius(5px);

          &:before {
            position: absolute;
            font-size: 128px;
            top: 4rem;
            color: $blue;
          }
        }
      }

      ul {
        margin-left: 138px;
        border-left: 1px dotted $grey;
        min-height: 128px;
      }
    }
  }


  .donors {

    .slick-list {
      margin:0;

      .slide__content {
        height: 150px;

        figure img {
          max-height: 150px;
          width: auto;
        }
      }
    }

  }

  /* accordion */

  .accordions {

    .ui-accordion {

      h3 {
        font-size: 14px;
      }

      .text-container * {
        font-size: 1em;
      }
    }

    &.striped {

      .theme-two,
      .theme-one {
        margin: 0 -20px;
      }
    }
  }
}


/* IPAD Landscape */
@media only screen
and (max-device-width : 1024px)
and (orientation : landscape) {

  header {

    .mobile-search-trigger {
      display: none;
    }

    .secondary-menu-wrapper {

      margin-right: 0;

      #views-exposed-form-search-search {
        display: none;
      }

      nav.secondary-menu {
        margin: 0;

        ul {


          li {
            margin-right: $colPadding/2;

            a {
              @include condensedFontBold;
              font-size: 1rem;
            }

            ul {
              display: none;
            }
          }
        }
      }
    }

    .main-menu-wrapper {
      nav.main-menu {
        ul li a {
          @include condensedFontBold;
          padding: 8px 16px;
        }
      }
    }


  }

  .front .view-mode-list.node-news .wrapper .content {
    display: none;
  }

  .slick-initialized ~ .slick__arrow {
    display: none;
  }

  #search-box {
    display: inline-block;
  }

  .mobile-only {

    display: block;


  }

  .search-bar {

    form {
      .views-exposed-widget {
        width: 100%;
        margin: 0 0 20px 0;

        input[type="text"] {
          margin-bottom: 0;
        }

      }
    }

  }

  .page-primary-submenu .menu {
    li {

      margin: 0 $colPadding/2 $colPadding/2 0;

      a {
        @include condensedFont;
        padding: 6px 12px;
      }
    }

  }

  article.view-mode-teaser.vocabulary-outcome-theme > a > div .content {
    display: none;
  }

  aside h2 {
    font-size: 1.5rem;
  }

  .view-mode-full.field-collection-item-field-testimonials-fc {
    figure {
      max-width: 20%;
    }
    .wrapper {
      display: table-cell;
      max-width: 80%;

      .position,
      h3 {
        margin-left: 0;
      }

      blockquote {
        margin-left: 0;
        margin-top: $colPadding;
      }
    }
  }

  .knowledge-tools-banners li > div:before {
    width: 120px;
    font-size: 48px;
    padding: 36px;
  }

  /*
  footer .logo figcaption {
    font-size: 0.6rem;
    margin-top: 5px;
  }
  */
  .view-display-id-by_region {

    .item-list {
      margin: 0;

      h3 {
        @include condensedFontBold;
        font-size: 1.4rem;
        position: relative;
        top: 20px;
        line-height: 1;
      }

      ul {
        margin: 0;
        padding: $colPadding 0;
        text-align: center;
        border: 0;
        min-height: 215px;

        &:before {
          display: none;
        }
      }
    }
  }

}
/* IPHONE X */
@media only screen
and (min-width : 768px)
and (max-width : 823px) {
  /* Jobs */
  .PSOFOShop {

    form {

      fieldset {

        #div-sagparam1 {
          input {
            width: 417px;
          }
        }

        #div-srcparam3 {
          width: 285px;
        }
      }
    }

    /* Subscribe */
    &#FOsaComp {

      #div-sagparam2 {
        margin-right: 0;
      }
      #div-sagparam3 {


        label:first-child {
          position: relative;
          top: 0;
          float: none;
          clear: both;
          margin-bottom: $colPadding/2;

        }
      }
    }
  }

}


/* IPHONE X */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 823px)
and (orientation : landscape) {




  #search-box {
    display: none;
  }


  .mobile-search-trigger {
    position: absolute;
    top: 21px;
    right: $colPadding/2;
  }

  header {
    .main-menu-wrapper {
      nav.main-menu {
        ul li {

          &:first-child {
            a {
              width: 80px;
            }
          }

          a {
            @include condensedFontBold;
            padding: 8px 10px;
          }

        }
      }
    }

    .secondary-menu-wrapper {
      margin-right: $colPadding/2;
    }
  }

}


@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 2) {

  header {

    .mobile-search-trigger {
      display: block;
      position: absolute;
      top: 21px;
      right: $colPadding/2;
    }

    .secondary-menu-wrapper {

      margin-right: $colPadding/2;

      #views-exposed-form-search-search {
        display: none;
      }

      nav.secondary-menu {
        margin: 0;

        ul {


          li {
            margin-right: $colPadding/2;

            a {
              @include condensedFontBold;
              font-size: 1rem;
            }

            ul {
              display: none;
            }
          }
        }
      }
    }

    .main-menu-wrapper {
      nav.main-menu {
        ul li {

          &:first-child {
            a {
              width: 80px;
            }
          }

          a {
            @include condensedFontBold;
            padding: 8px 10px;
          }

        }
      }
    }


  }

  .front .view-mode-list.node-news .wrapper .content {
    display: none;
  }

  .slick-initialized ~ .slick__arrow {
    display: none;
  }

  #search-box {
    display: none;
  }

  .mobile-only {

    display: block;

  }

  .search-bar {

    form {
      .views-exposed-widget {
        width: 100%;
        margin: 0 0 20px 0;

        input[type="text"] {
          margin-bottom: 0;
        }

      }
    }

  }

  .page-primary-submenu .menu {
    li {

      margin: 0 $colPadding/2 $colPadding/2 0;

      a {
        @include condensedFont;
        padding: 6px 12px;
      }
    }

  }

  article.view-mode-teaser.vocabulary-outcome-theme > a > div .content {
    display: none;
  }

  aside h2 {
    font-size: 1.5rem;
  }

  .view-mode-full.field-collection-item-field-testimonials-fc {
    figure {
      max-width: 20%;
    }
    .wrapper {
      display: table-cell;
      max-width: 80%;

      .position,
      h3 {
        margin-left: 0%;
      }

      blockquote {
        margin-left: 0%;
        margin-top: $colPadding;
      }
    }
  }

  .knowledge-tools-banners li > div:before {
    width: 120px;
    font-size: 48px;
    padding: 36px;
  }

  /*
  footer .logo figcaption {
    font-size: 0.6rem;
    margin-top: 5px;
  }
  */

  .view-display-id-by_region {

    .item-list {
      margin: 0;

      h3 {
        @include condensedFontBold;
        font-size: 1.4rem;
        position: relative;
        top: 20px;
        line-height: 1;
      }

      ul {
        margin: 0;
        padding: $colPadding 0;
        text-align: center;
        border: 0;
        min-height: 215px;

        &:before {
          display: none;
        }
      }
    }
  }


}
