article.view-mode-teaser {

  display:table;
  vertical-align: top;
  width: 100%;
  margin-bottom: $colPadding;

  background: $cream;

  .striped &  {
    background: $white;
  }



  aside & {
    background: $cream;
  }

  figure {
    display:table-cell;
    width:30%;
    font-size: 0;

    img {
      max-width: 100%;
      height: auto;
    }

  }
  .wrapper {
    display: table-cell;
    vertical-align: top;
    width: 70%;
    padding: $colPadding;
    font-size: $normalTextSize;
    color: $tertiaryColor;
    line-height: 1.2;

    h3 {
      font-size: $bigTextSize;
      margin-bottom: $colPadding/4;

      a {
        text-decoration: none;
        color: $tertiaryColor;
        @include titleFontBold;
        line-height: 1.2;
        @include transition(all 300ms ease-in-out);


        &:hover {
          color: $primaryColor;
        }
      }
    }

    .author {
      font-size: $tinyTextSize;
      @include textFontLightItalic;
      margin-bottom: $colPadding/2;
      a {
        text-decoration: none;
        @include textFontBold;
        font-style: normal;

      }
    }

    .content {
      @include textFont;
      font-size: $normalTextSize;

      &:after {
        content: '...';
      }
    }

  }


  &.node-ui-banner {

    > a.group-link {
      display: block;
      padding: 0;

      &:hover {

        > div {
          background: $yellow;

          h3, .subtitle {
            color: $black;
          }

          .content {
            background: $orange;

            p {
              color: $white;
            }
          }



        }
      }
    }

    .wrapper {
      display: block;
      width: 100%;
      background: $blue;
      padding: $colPadding;
      position: relative;
      text-align: center;
      @include transition(all 300ms ease-in-out);

      h3 {
        //margin-top: 140px;
        text-align: center;
        color: $white;
        font-size: 2rem;
        @include condensedFontBold;
        display: inline-block;
        margin:0;
        @include transition(color 300ms ease-in-out);
      }

      .subtitle {
        color: $white;
        font-size: 1.3rem;
        @include condensedFont;
        @include transition(color 300ms ease-in-out);
      }

      .content {
        background: $white;
        padding: $colPadding;
        margin-top: $colPadding;
        @include transition(all 300ms ease-in-out);

        p {
          display: table-cell;
          vertical-align: middle;
          font-size: 1rem;
          margin: 0;
          @include textFontLight;
          color: $darkGrey;
          height: 140px;
          @include transition(all 300ms ease-in-out);
        }

        &:after {
          display: none;
        }
      }
    }

  }

  &.vocabulary-tools {

    background: transparent;

    figure {
      width: 100%;
      display: block;
      padding-bottom: $colPadding/2;
      border-bottom: 1px dotted $grey;
      margin-bottom: $colPadding/2;
      text-align: center;

      img {
        height: 60px;
        width: auto;
        max-width: auto;
        margin: 0 auto;
      }
    }

    h3 {
      text-align: center;
      font-size: 1.4rem;
      margin-bottom: $colPadding/4;
    }

    .description {
      font-size: $veryTinyTextSize;
      text-align: center;
      @include titleFontLight;
      color: $grey;
    }
  }


  &.vocabulary-goal {
    display: block;
    background: transparent;
    max-width: 65em;
    margin: 0 auto;
    padding: $colPadding/2;
    background: $lightGreen;

    h3 {
      text-align: left;
      font-size: 22px;
      @include titleFontBold;
      margin-bottom: $colPadding;
      color: $darkGrey;
    }

    .wrapper {
      display: table;
      vertical-align: top;
      width: 100%;
      padding: 0;

      figure {
        display: table-cell;
        width: 50%;
        vertical-align: top;

        @media only screen and (max-width: $tabletBreakPoint) {
          display: block;
          width: 100%;
          min-height: 30vh;
        }
      }

      .text-container {
        display:table-cell;
        width: 50%;
        vertical-align: top;
        padding: $colPadding;
        background: $white;

        @media only screen and (max-width: $tabletBreakPoint) {
          display: block;
          width: 100%;
        }
      }
    }
  }

  &.node-person {

    text-align: center;

    .block & {
      margin: 0;
    }

    .unfilled & {
      background: transparent;
    }

    figure {
      margin: 0 auto;

      img {
        @include border-radius(50%);
        border: 5px solid $primaryColor;
        max-width: 200px;
        height: auto;
        @include transform(translateY(0));
      }
    }

    h3 {

      @include titleFontBold;
      text-align: center;
      margin: $colPadding 0 0 0;
      font-size: 1.4rem;

      a {
        color: $darkGrey;
      }
    }

    .position {
      @include titleFontLight;
      text-align: center;
      font-size: $veryTinyTextSize;
      padding-bottom: $colPadding/2;
      margin-bottom: $colPadding/2;
      color: $darkGrey;
      border-bottom: 1px dotted $darkGrey;
    }

    .email,
    .phone {
      a {
        font-size: $normalTextSize;
        color: $black;

        &:before {
          position: relative;
          margin-right: 5px;
        }
      }
    }

    .email a:before {
      @extend .iconMail;
      top: 2px;
    }

    .phone a:before {
      @extend .iconPhone;
      top:1px;
    }
  }

  &.node-news {


    .wrapper {
      display: block;
      width: 100%;
      padding: $colPadding/2 $colPadding $colPadding $colPadding;

    }

    time {
      display: inline-block;
      margin-bottom: $colPadding/4;
    }

    figure {
      width: 100%;

      img {

      }
    }
  }


  &.node-research-network {


    a.teaser-anchor {
      color: initial;


      &.external {
        h3:after {
          @extend .iconLink;
          position: relative;
          top: 0px;
          margin-left: 5px;
          font-size: 1rem;
          color: $lightGrey;
          @include transition(all 300ms ease-in-out);
        }

        &:hover h3:after {
          color: $white;
        }
      }

      &:hover {
        .wrapper {
          background-color: $lightGreen;
          color: $white;

          h3 {
            color: $white;
          }
        }
      }
    }

    .wrapper {
      border: 10px solid $white;
      padding: 20px;
      width: 100%;
      @include transition(all 300ms ease-in-out);

    }

    h3 {
      font-size: 1.3rem;
      margin-bottom: $colPadding/2;
      padding-bottom: $colPadding/2;
      border-bottom: 1px dotted;
      @include transition(all 300ms ease-in-out);
    }

    .text-container {
      font-size: 1rem;
      @include titleFontLight;

      &:last-child {
        margin-bottom: 0;
      }
    }

  }


  &.vocabulary-outcome-theme {

    display: block;
    background: $stripeColor;

    figure {
      display: block;
      margin: $colPadding auto;
    }

    > a {

      &.teaser-anchor:hover {

        .wrapper {
          background: $yellow;

          h3 a,
          &:before {
            color: $black;
          }

          .content {
            color: $white;
            background: $orange;
          }

        }
      }


      .wrapper {

        background: $blue;
        padding: $colPadding;
        position: relative;


        h3 {
          //margin-top: 140px;
          margin-bottom: $colPadding;
          text-align: center;

          a {
            color: $white;
            font-size: 1.3rem;
            @include condensedFontBold;
            display: inline-block;
          }
        }

        .content {
          background: $white;
          font-size: 1rem;
          padding: $colPadding;
          @include textFontLight;
          color: $darkGrey;
          @include transition(all 300ms ease-in-out);
        }
        /*
        &:before {

          font-size: 128px;
          color: $white;
          position: absolute;
          left: 50%;
          @include transform(translateX(-50%));
          @include transition(all 300ms ease-in-out);

          .theme-1 & {
            @extend .iconTheme-1;
          }
          .theme-2 & {
            @extend .iconTheme-2;
          }
          .theme-3 & {
            @extend .iconTheme-3;
          }
          .theme-4 & {
            @extend .iconTheme-4;
          }
          .theme-5 & {
            @extend .iconTheme-5;
          }
          .theme-6 & {
            @extend .iconTheme-6;
          }
          .theme-7 & {
            @extend .iconTheme-7;
          }
          .theme-8 & {
            @extend .iconTheme-8;
          }
          .theme-9 & {
            @extend .iconTheme-9;
          }
        }
        */
      }
    }
  }

  &.node-publication {
    display: table;
    width: 100%;
    height: 140px;

    figure {
      display: table-cell;
      width: 120px;
      background: $white;

      .striped & {
        background: $green;
      }
    }

    .wrapper {
      display: table-cell;
      vertical-align: top;
      padding: $colPadding/2;
    }

    .type {
      display: inline-block;
      padding: 3px 6px;
      background: $yellow;
      @include condensedFontBold;
      font-size: $veryTinyTextSize;
      text-transform: uppercase;
      color: $black;

    }

    .year {
      position: absolute;
      top: $colPadding/4;
      right: $colPadding/2;
      @include titleFont;
      font-size: $tinyTextSize;
    }

    h3 {
      font-size: 1.3rem;
      margin: $colPadding/2 0;
    }

    .download {
      margin-top: $colPadding/2;

      a {
        display: inline-block;
        padding: 3px 6px;
        border: 1px solid $black;
        @include border-radius(3px);
        text-transform: uppercase;
        color: $black;
        @include transition(all 300ms ease-in-out);

        &:hover {
          border: 1px solid $orange;
          background: $orange;
          color: $white;
        }
      }
    }
  }

}

