.ajax-progress-throbber {
  position: absolute;
}
.views_infinite_scroll-ajax-loader {
  text-align: center;
  margin-bottom: $colPadding * 2;
  margin-top: - $colPadding * 2;
  background: $cream;
  width: 30%;
  height: 20px;
  margin: 0 auto;
  position:relative;

  img {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    width: 0px;
    background: $orange;
    @include animation(1.6s ease infinite loader);
  }
}
