#search-box {

  display:inline-block;
  label {
    display: none;
  }

  .form-item.form-type-textfield  {
    position:relative;
    border-top: 2px solid $tertiaryColor;
    border-bottom: 2px solid $tertiaryColor;
    border-left: none;
    border-right: none;
    height: 32px;
    margin: 0 0 0 32px;

    &:before {
      @extend .iconSearch;
      @include border-radius(50% 0 0 50%);
      display: block;
      position:absolute;
      width: 32px;
      border: 2px solid $tertiaryColor;
      top: -2px;
      left: -32px;
      bottom: -2px;
      border-right: 0;
      padding: 7px 7px;
      color: $tertiaryColor;
    }

    &:after {
      content: '';
      @include border-radius(0 50% 50% 0);
      display: block;
      position:absolute;
      width: 32px;
      border: 2px solid $tertiaryColor;
      border-left: 0;
      top: -2px;
      right: -16px;
      bottom: -2px;
    }

    input[type="text"] {
      border: none;
      padding: 0;
      margin:0;
      line-height: 28px;

      &:focus {
        outline: none;
        background-color: $white;
      }
    }

  }


  input[type="submit"] {
    display: none;
  }

}

.mobile-search-trigger {

  display: inline-block;

  span {
    display: none;
  }

  &:before {
    @extend .iconSearch;
    display: block;
    width: 32px;
    padding: 7px 7px;
    color: $tertiaryColor;
  }
}

.search-bar {
  background: $yellow;
  padding: $colPadding*2 0;

  form {

    font-size: $normalTextSize;
    label {
      display: block;
      margin-bottom: $colPadding/2;
      font-size: 1.3rem;
      @include condensedFont;
    }

    .views-exposed-widget {
      display: inline-block;
      margin-right: $colPadding;

      input[type=text] {
        font-size: $normalTextSize;
        padding: $colPadding;
        width: 400px;

        @media only screen and (max-width: $tabletBreakPoint) {
          width: 100%;
          margin-bottom: $colPadding;
        }
      }

      select {
        @media only screen and (max-width: $tabletBreakPoint) {
          width: 100%;
        }
      }

      .select2-container-active {
        a {
          @include box-shadow(none);
        }
      }

      .select2-container {

        //margin-bottom: $colPadding;

        &.select2-dropdown-open {
          .select2-choice .select2-arrow b:before {
            @extend .iconArrowUp;
          }
        }

        .select2-choice {
          padding: 30px;
          @include border-radius(0);
          border-color: #cccccc;

          .select2-chosen {
            top: -10px;
            position: relative;
            left: -10px;
            margin: 0;
            @include titleFontItalic;
          }

          .select2-arrow {
            background: transparent;
            border: none;
            height: auto;
            top: 50%;
            right: 5px;
            @include transform(translateY(-50%));
            width: 25px;

            b {
              width: 30px;
              height: 25px;
              background: none !important;

              &:before {
                @extend .iconArrowDown;
                font-size: 24px;
                color: $grey;
              }
            }
          }
        }
      }
    }

    .views-submit-button {
      input {
        background: $orange;
        color: $white;
        padding: $colPadding;
        border: none;
        font-size: $normalTextSize;
        @include titleFontBold;
      }
    }
  }

}

.select2-results {

  .select2-result-label {
    @include titleFontLight;
    font-size: $normalTextSize;
    color: $darkGrey;
    padding: $colPadding/2;

  }

  .select2-highlighted {

    .select2-result-label {
      color: $white;
      background: $orange;
    }
  }
}

