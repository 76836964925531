.main-menu-wrapper {
  clear:both;
  background: $white;

  nav.main-menu {

    & > ul {
      margin: 0;

      & > li {
        display: inline-block;
        position: relative;

        &:hover {

          & > ul {
            display: block;
          }
        }

        &.themes ul li {
          width: 250px;
        }

        a {
          color: #222;
          text-decoration: none;
          padding: 12px 24px;
          display: block;
          font-size: $bigTextSize;
          @include titleFontBold;
          @include transition(all 300ms ease-in-out);

          &.active,
          &.active-trail,
          &:hover {
            background: $menuGrey;
            color: #222;
          }

          &.home {
            text-indent: -9999px;
            width:110px;
            position:relative;

            &:before {
              @extend .iconHome;
              display: inline-block;
              position: absolute;
              left: 50%;
              top: 50%;
              text-indent: 0;
              @include transform(translate(-50%, -50%));
            }
          }

        }

        /* disable third level */
        ul ul {
          display: none;
        }
        /* second level */
        ul {
          display: none;
          position: absolute;
          top: calc(36px + .4em);
          z-index: 100;
          background: $white;
          padding: $colPadding/2;

          &:before {
            @include triangle(top, $white);
            position: absolute;
            top: -.4em;
            left: .4em;
          }

          li {
            display: block;
            width: 162px;

            &:last-child a:hover,
            &:last-child a {
              border-bottom: none;
            }


            a {
              position: relative;
              padding: $colPadding/2 $colPadding/2;
              @include titleFontBold;
              font-size: $tinyTextSize;
              border-bottom: 1px solid $secondaryColor;


              &:before {
                font-size: 32px;
                position:absolute;
                left: $colPadding/4 + 1px;
                top: $colPadding/4 + 1px;

              }

              &:hover {
                @include border-radius(3px);
                border-bottom: 1px solid transparent;
              }
              /*
              &.theme-1,
              &.theme-2,
              &.theme-3,
              &.theme-4,
              &.theme-5,
              &.theme-6,
              &.theme-7,
              &.theme-8,
              &.theme-9 {
                padding-left: 50px;
              }

              &.theme-1:before {
                @extend .iconTheme-1;
              }
              &.theme-2:before {
                @extend .iconTheme-2;
              }
              &.theme-3:before {
                @extend .iconTheme-3;
              }
              &.theme-4:before {
                @extend .iconTheme-4;
              }
              &.theme-5:before {
                @extend .iconTheme-5;
              }
              &.theme-6:before {
                @extend .iconTheme-6;
              }
              &.theme-7:before {
                @extend .iconTheme-7;
              }
              &.theme-8:before {
                @extend .iconTheme-8;
              }
              &.theme-9:before {
                @extend .iconTheme-9;
              }
              */
            }
          }
        }
      }
    }

  }
}

.page-primary-submenu {
  background: $primaryColor;
  //border-top: 10px solid $secondaryColor;
  padding: $colPadding/2 0 0 0;
  @include box-shadow(0px 2px 2px 0 rgba(0,0,0,0.6));

  .menu {
    @extend .container;
    @extend .with-content;

    li {
      position: relative;
      display: inline-block;
      margin-right: $colPadding/2;
      margin-bottom: $colPadding/2;

      &:last-child {
        margin-right: 0;
      }

      &:hover {

        & > ul {
          display: block;
        }
      }

      a {
        color: $white;
        text-decoration: none;
        padding: 12px 24px;
        display: block;
        font-size: $bigTextSize;
        @include titleFont;
        @include transition(all 300ms ease-in-out);
        border: 1px solid rgba(255,255,255,0.2);

        &.active,
        &.active-trail,
        &:hover {
          background: $secondaryColor;
          color: $white;
          border: 1px solid $secondaryColor;
        }



        &.home {
          text-indent: -9999px;
          width:110px;
          position:relative;

          &:before {
            @extend .iconHome;
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            text-indent: 0;
            @include transform(translate(-50%, -50%));
          }
        }
      }

      /* disable third level */
      ul ul {
        display: none;
      }
      /* second level */
      ul {
        display: none;
        position: absolute;
        top: calc(36px + .4em);
        width: 200px;
        z-index: 100;
        background: darken($primaryColor, 10);
        padding: $colPadding/2;

        &:before {
          @include triangle(top, darken($primaryColor, 10));
          position: absolute;
          top: -.4em;
          left: .4em;
        }

        li {
          display: block;
          width: 180px;
          margin-bottom: 0;

          &:last-child a:hover,
          &:last-child a {
            border-bottom: none;
          }


          a {
            position: relative;
            padding: $colPadding/4 $colPadding/2;
            @include textFontLight;
            font-size: $tinyTextSize;
            border: 1px solid transparent;
            border-bottom: 1px solid darken($primaryColor, 7);


            &:before {
              font-size: 32px;
              position:absolute;
              left: $colPadding/4 + 3px;
              top: $colPadding/4 + 3px;

            }

            &:hover {
              @include border-radius(3px);
              border-bottom: 1px solid transparent;
            }
          }
        }
      }
    }
  }
}
