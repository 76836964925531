.donors-home-slideshow {

  &.funders {
    img {
      display: none;
    }


    &:before {
      display: inline-block;
      width: 135px;
      height: 71px;
      content: ' ';
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      @include box-shadow(0px 2px 3px rgba(0, 0, 0, .4));
    }

    &.funders-india:before {
      background-image: url('../images/flags/india.png');
    }

    &.funders-america:before {
      background-image: url('../images/flags/america.png');
    }

    &.funders-england:before {
      background-image: url('../images/flags/england.png');
    }

    &.funders-germany:before {
      background-image: url('../images/flags/germany.png');
    }

    &.funders-japan:before {
      background-image: url('../images/flags/japan.png');
    }

    &.funders-philippines:before {
      background-image: url('../images/flags/philippines.png');
    }

    &.funders-switzerland:before {
      background-image: url('../images/flags/switzerland.png');
    }

  }

  figcaption {
    display: none;
  }

}


.donors {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: $colPadding*2;
  margin-bottom: $colPadding*2;

  .slide__content {
    display: table;
    vertical-align: middle;
    width: 100%;
    height: 200px;
    text-align: center;
    border: none;


    &:hover {
      @include box-shadow(none);
    }

    .slide__grid {
      display: table-cell;
      vertical-align: middle;
    }

    figure {
      margin: 0 $colPadding;
      img {
        max-width: 100%;
      }
      figcaption {
        display: none;
      }
    }


  }
  .slick__arrow {

    .slick-prev,
    .slick-next {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));

      &:before {
        display: block;
        font-size: 36px;
        width: 36px;
        color: $tertiaryColor;
        @include transition(all 300ms ease-in-out);

      }

      &:hover:before {
        color: $primaryColor;
      }
    }

    .slick-prev {
      left: 0;

      &:before {
        @extend .iconPrev;
      }
    }

    .slick-next {
      right: 0;
      &:before {
        @extend .iconNext;
      }
    }
  }

}

.view-taxonomy-partners {
  margin-top: $colPadding*4;

  .view-content {
    li {
      @include titleFontBold;
      font-size: $tinyTextSize;
    }
  }
}


.page-investors {

  section.donor-feature {
    margin: 0;
    .container {
      max-width: 450px;
    }


  }

  section.partners,
  section.donor-bronze,
  section.donor-gold,
  section.donor-silver,
  section.donor-platinum {


    h3 {
      text-align: center;
      @include condensedFontLight;
      font-size: 1.5rem;
      margin-bottom: $colPadding;
    }

    .container {
      max-width: 900px;
    }

    .view-mode-full.node-donor {
      margin-bottom: 0;
    }

    .donors-list {
      text-align: left;
      li {
        display: inline-block;
        margin-right: $colPadding;
        margin-bottom: $colPadding;

        &:last-child {
          margin-right: 0;
        }

      }
    }

    .with-logo  {

      .node-donor {

        .img-container {
          display: flex;
          align-items: center;
          min-height: 80px;
        }
        img {
          display: block;
          margin: 0 auto;
        }

        figcaption {
          margin-top: $colPadding/2;
          padding-top: $colPadding/2;
          border-top: 1px dotted $lightGrey;
          text-align: center;
        }
      }
    }

    .node-donor {
      //width: 150px;

      img {
        display: none;
      }



      figcaption {
        @include titleFontBold;
        font-size: $tinyTextSize;

        a {
          color: inherit;

          &:after {
            @extend .iconLink;
            color: $lightGrey;
            margin-left: 2px;
          }

          &:hover {
            color: $blue;
          }
        }
      }

      .description {
        @include condensedFontLight;
        font-size: 10px;

        ul {

          li {
            margin-bottom: $colPadding/5;
          }
        }
      }
    }
  }

  section {


    .funders {
      text-align: center;

      &:before {
        display: inline-block;
        width: 100px;
        height: 80px;
        content: ' ';
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;

      }

      &.funders-india:before {
        background-image: url('../images/flags/india.png');
      }

      &.funders-america:before {
        background-image: url('../images/flags/america.png');
      }

      &.funders-england:before {
        background-image: url('../images/flags/england.png');
      }

      &.funders-germany:before {
        background-image: url('../images/flags/germany.png');
      }

      &.funders-japan:before {
        background-image: url('../images/flags/japan.png');
      }

      &.funders-philippines:before {
        background-image: url('../images/flags/philippines.png');
      }

      &.funders-switzerland:before {
        background-image: url('../images/flags/switzerland.png');
      }

    }



    &.donor-platinum {
      .container {
        padding-top: $colPadding*2;
        border-top: 1px dotted $lightGrey;
      }
    }

    &.donor-gold {
    //  background: $gold;
      margin: 0;

      .view-content-donors.view-display-id-panel_pane_6 {
        margin-top: $colPadding*2;
      }

    }

    &.donor-silver {
    //  background: $silver;
      margin: 0;

      .container {
        max-width: 900px;
      }

    }

    &.donor-bronze {
      //  background: $bronze;
      margin: 0;
    }

  }

}