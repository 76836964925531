.item-list {
  margin: 0;

  li {
    margin-bottom: $colPadding;

    &:last-child {
      margin-bottom: 0;
    }


  }
}
.striped {
  .item-list {
    article.node {
      background: $white;
    }
  }
}


.view-mode-list {

  margin-bottom: $colPadding;

  .teaser-anchor {
    display: block;
    font: 0/0;

    &:hover {
      h3 a {
        color: $primaryColor;
      }
    }
  }

  &.node-person {

    figure {
      overflow: hidden;
      @include border-radius(50%);
      border: 5px solid $primaryColor;
      max-width: 210px;
      height: auto;
      font-size: 0;
      margin: 0 auto;
      @include transition(all 300ms ease-in-out);

    }
    img {
      max-width: 100%;
      height: auto;
      @include transition(all 400ms ease-in-out);
    }

    h3 {
      @include titleFontBold;
      text-align: center;
      margin: $colPadding 0 0 0;
      font-size: 1.4rem;

      a {
        color: $darkGrey;
      }
    }

    a.teaser-anchor {
      color: $darkGrey;

      &:hover {

        h3 a {
          color: $primaryColor;
        }

        figure {
          border-color: $yellow;
        }

        img {
          @include transform(scale(1.1));
        }
      }
    }

    .position {
      @include titleFontLight;
      text-align: center;
      font-size: $veryTinyTextSize;
    }

    .position-notes {
      display: none;
      @include titleFontLight;
      text-align: center;
      font-size: $veryTinyTextSize;
      color: $darkGrey;

      .view-id-content_person.view-display-id-board & {
        display: block;
      }
    }

    .expertise {
      @include titleFontLight;
      text-align: center;
      border-top: 1px dotted $darkGrey;
      padding-top: $colPadding/2;
      margin-top: $colPadding/2;
      color: $darkGrey;
      font-size: $normalTextSize;
    }

  }

  &.node-news {

    display:table;
    vertical-align: top;
    width: 100%;

    @media only screen and (max-width: $tabletBreakPoint) {
      display: block;
    }

    figure {
      display:table-cell;
      width:30%;

      @media only screen and (max-width: $tabletBreakPoint) {
        display: block;
      }

      img {
        max-width: 100%;
      }

    }

    .wrapper {
      display: table-cell;
      vertical-align: top;
      width: 70%;
      padding: $colPadding;
      font-size: $normalTextSize;
      color: $tertiaryColor;
      line-height: 1.2;
      background: $white;

      @media only screen and (max-width: $tabletBreakPoint) {
        display: block;
        width: 100%;
      }

      h3 {
        font-size: $bigTextSize;
        margin-bottom: $colPadding/4;
        a {
          text-decoration: none;
          color: $tertiaryColor;
          @include titleFontBold;
          line-height: 1.2;
          @include transition(all 300ms ease-in-out);

          &:hover {
            color: $primaryColor;
          }
        }
      }

      .author {
        font-size: $tinyTextSize;
        @include textFontLightItalic;
        margin-bottom: $colPadding/2;
        a {
          text-decoration: none;
          @include textFontBold;
          font-style: normal;
        }
      }

      .content {
        @include textFont;
        font-size: $normalTextSize;

        &:after {
          content: '...';
        }
      }

    }

    .teaser-anchor {
      display: table;
      width: 100%;
      font: 0/0;

      &:hover {
        h3 a {
          color: $primaryColor;
        }
      }
    }

    .featured & {

      position: relative;
      display: block;

      .teaser-anchor:hover h3 a {
        color: $white;
        background: $secondaryColorAlpha;
      }

      .content,
      .author {
        display: none;
      }

      figure {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          display: block;
          opacity: 0;

          @media only screen and (max-width: $tabletBreakPoint) {
            height: 300px;
          }
        }
      }

      .wrapper {
        display: block;
        padding: 0;
      }

      h3 {
        position: absolute;
        right: 0;
        bottom: $colPadding;
        left: 0;
        margin: 0;

        a {
          color: $white;
          display: block;
          text-decoration: none;
          font-size: $bigTextSize;
          padding: $colPadding/2;
          background: $primaryColorAlpha;
          @include transition(all 300ms ease-in-out);

          &:hover {
            color: $white;
            background: $secondaryColorAlpha;
          }
        }
      }

    }

  }
}