/*
.themes-menu {
  background: #ee9f33;
  margin-bottom: $colPadding*10;

  .themes {
    position: relative;
    li {

      margin:0;

      a {
        color: $white;
        text-decoration: none;

        text-align: center;
        display: block;
        @include transition(all 300ms ease-in-out);

        &:hover {
          background: #fcd130;
        }

        &:before {
          font-size: 64px;
          padding: 15px 0;
          display: block;
        }
        &.theme-1:before {
          @extend .iconTheme-1;
        }
        &.theme-2:before {
          @extend .iconTheme-2;
        }
        &.theme-3:before {
          @extend .iconTheme-3;
        }
        &.theme-4:before {
          @extend .iconTheme-4;
        }
        &.theme-5:before {
          @extend .iconTheme-5;
        }
        &.theme-6:before {
          @extend .iconTheme-6;
        }
        &.theme-7:before {
          @extend .iconTheme-7;
        }
        &.theme-8:before {
          @extend .iconTheme-8;
        }
        &.theme-9:before {
          @extend .iconTheme-9;
        }


        span {
          color: $tertiaryColor;
          position: absolute;
          top: 0;
          width: 110px;
          font-size: $tinyTextSize;
          @include condensedFontBold;
          display:block;
          @include transform(translateY(110px));
          width: 8%;
          font-size: 0.85rem;
        }
      }
    }
  }
}
*/
ul.themes {
  .button {
    a {
      display: inline-block;
      padding-left: 48px;
      position: relative;
      line-height: 1.2;

      &:before {
        font-size: 36px;
        padding: 15px 0;
        display: block;
        position: absolute;
        left: 6px;
        top: 50%;
        @include transform(translateY(-50%));
      }

    }

    &.theme-1 a:before {
      @extend .iconTheme-1;
    }
    &.theme-2 a:before {
      @extend .iconTheme-2;
    }
    &.theme-3 a:before {
      @extend .iconTheme-3;
    }
    &.theme-4 a:before {
      @extend .iconTheme-4;
    }
    &.theme-5 a:before {
      @extend .iconTheme-5;
    }
    &.theme-6 a:before {
      @extend .iconTheme-6;
    }
    &.theme-7 a:before {
      @extend .iconTheme-7;
    }
    &.theme-8 a:before {
      @extend .iconTheme-8;
    }
    &.theme-9 a:before {
      @extend .iconTheme-9;
    }

  }
}


.themes-menu {


  .themes {
    margin: 120px 0 80px 0;

    ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 80%;
      max-width: 80%;
      margin: 0 auto;
      text-align: center;

      li {
        margin: 0 30px $colPadding*2 0;
        display: inline-block;
        max-width: 160px;
        width: 160px;

        &:nth-child(5n),
        &:last-child {
          margin-right:0;
        }



        a {
          color: $white;
          text-decoration: none;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 80px;
            height: auto;
            position: absolute;
            z-index: 1;
            margin-top: 8px;
          }

          &:hover {

            span {
              color: $black;
              margin-top: 20px;
            }

            i {

              background: $themes-icon-green;
              @include transform(scale(1.1));

              &:before {
                color: $black;
                @include transform(rotate(0deg));
              }
            }
          }

          i {

            @include border-radius(10px);
            width: 96px;
            height: 96px;
            @include transform(rotate(45deg));
            background: $themes-icon-green;
            display: inline-block;
            position: relative;
            @include transition(all 300ms ease-in-out);

            /*
            &:before {
              font-size: 64px;
              display: inline-block;
              position: absolute;
              top: 16px;
              left: 16px;
              @include transform(rotate(-45deg));
              @include transition(all 300ms ease-in-out);

            }

             */
          }
          /*
          &.theme-1 i:before {
            @extend .iconTheme-1;
          }
          &.theme-2 i:before {
            @extend .iconTheme-2;
          }
          &.theme-3 i:before {
            @extend .iconTheme-3;
          }
          &.theme-4 i:before {
            @extend .iconTheme-4;
          }
          &.theme-5 i:before {
            @extend .iconTheme-5;
          }
          &.theme-6 i:before {
            @extend .iconTheme-6;
          }
          &.theme-7 i:before {
            @extend .iconTheme-7;
          }
          &.theme-8 i:before {
            @extend .iconTheme-8;
          }
          &.theme-9 i:before {
            @extend .iconTheme-9;
          }
          */
          span {
            color: $tertiaryColor;
            font-size: 1.2rem;
            @include condensedFontBold;
            display: table;
            margin-top: 30px;
            min-height: 70px;
            @include transition(all 300ms ease-in-out);
          }
        }
      }
    }
  }

}
