.block {

  padding: $colPadding;
  background: $cream;
  margin-bottom: $colPadding;
  overflow: auto;

  .metadata-field {
    margin-bottom: $colPadding/2;
  }

  .button {
    margin-bottom: $colPadding/4;
  }

  ul {
    margin-bottom: $colPadding/4;
  }



  figure.vocabulary-sustainable-development-goals {

    width: 47%;
    margin-right: 3%;
    margin-left: 0;
    font: 0/0;
    float: left;

    img {
      max-width: 100%;
      height: auto;
    }

    figcaption {
      display: none;
    }

    &:nth-child(2n) {
      margin-left: 3%;
      margin-right: 0;
    }

    &:nth-child(n) {
      //clear:left;
    }
  }
}