section {

  .container.publications {

    h2 {
      margin-bottom: $colPadding*2;
    }

    & > h3 {
      font-size: 1.2rem;
      text-transform: uppercase;
      border-bottom: 1px solid $lightGrey;
    }

    .n-up li:only-child {
      float: left;
    }



  }
}