footer {

 // margin-top: $colPadding*4;
  background: $tertiaryColor;
  color: $white;
  padding: $colPadding*2 0 0 0;

  .logo {
    position: relative;
    height: 175px;
    background: url('../images/IRRI-logo-bandaid-05.png') no-repeat;
    background-size: contain;

    a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &:hover {
        text-decoration: none;
      }

    }

    h2 {
      display: none;
    }

  }

  * {
    font-size: $tinyTextSize;
    @include textFontLight;
  }
  a {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  h4 {
    margin-bottom: $colPadding / 2;
    @include textFont;
    text-transform: uppercase;
    color: $white;
  }

  .col {
    border-right: 1px dotted $white;

    &:last-child {
      border: none;
    }
  }

  ul {
    margin-bottom: $colPadding / 2;
  }

  p {
    margin-bottom: $colPadding / 2;
  }



  .address,
  .phone,
  .fax,
  .mail {
    display: inline-block;
    position:relative;
    margin-left: $colPadding;

    &:before {

      display: block;
      position: absolute;
      left: -$colPadding;
      font-size: 1rem;
      top: 2px;
    }
  }

  .address:before {
      @extend .iconMarker;
  }
  .phone:before {
    @extend .iconPhone;
  }

  .fax:before {
      @extend .iconFax;
  }

  .mail {
   margin-bottom: $colPadding / 2;

    &:before {
      @extend .iconMail;
    }
  }

  .social {

    li {
      display: inline-block;
      margin-right: $colPadding / 4;
      a {
        position: relative;
        text-indent: -9999px;
        display: inline-block;
        width: 24px;

        &:hover {
          text-decoration: none;
        }

        &:before {
          font-size: 24px;
          display: inline-block;
          position: absolute;
          left: 50%;
          top: 50%;
          text-indent: 0;
          @include transform(translate(-50%, -50%));
        }

        &.facebook:before {
          @extend .iconFacebook;
        }
        &.twitter:before {
          @extend .iconTwitter;
        }
        &.flickr:before {
          @extend .iconFlickr;
        }
        &.youtube:before {
          @extend .iconYoutube;
        }
        &.linkedin:before {
          @extend .iconLinkedin;
        }
        &.pinterest:before {
          @extend .iconPinterest;
        }
      }
    }
  }

  .legal {
    padding: $colPadding $colPadding $colPadding 0;
    text-align: right;
    margin: 0;

    li {
      display: inline-block;

      &:after {
        content: '|';
        margin: 0 3px;
      }

      &:last-child:after {
        display: none;
      }
    }

    .cc {
      img {
        width: 90px;
        position: relative;
        top: 10px;
      }
    }
  }

}
