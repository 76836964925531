.ar2017-banner {

  padding: $colPadding*4 $colPadding;
  background: url('../images/banner-1.jpg') 50% 50% no-repeat;
  background-size: cover;
  position:relative;

  .bg-overlay {
    background-color: rgba(238,159,51,0.8);
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }

  h3 {
    color: $white;
    @include titleFontLight;
    font-size: 2rem;
    position:relative;
    @include text-shadow(0 1px 2px rgba(34, 34, 34, 0.7));
    small {
      display: block;
      @include titleFontBold;
      font-size: 2.5rem;
      margin-top: $colPadding/2;
    }
  }

  ul {
    list-style: none;
    margin-top: $colPadding*2;
    position:relative;
    li {
      display: inline-block;
      margin-right: $colPadding;

      @media only screen and (max-width: $tabletBreakPoint) {
        margin-bottom: $colPadding;
      }

      .button {
        a {
          background: $white;
          font-size: 1.1rem;
          color: $black;

          &:hover {
            background: $yellow;
          }
        }
      }
      &:last-child {
        margin-right: none;
      }
    }
  }


}