.front {

  .highlights {
    padding-top: 120px;

    h2 {
      @include transform(translateY(60px));
    }
  }
}
