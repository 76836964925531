.text-container {

  max-width: 55em;

  .full-width & {
    max-width: 100%;
  }

  .centered & {
    margin: 0 auto;
  }

  ul, ol, h3, h4, h5, blockquote, figure {
    clear: both;
  }
  ul {
    list-style: outside;
    list-style-position: outside;
    margin: 0 0 1.5em $colPadding*1.5;
  }

  ol {
    margin: 0 0 1.5em $colPadding*1.5;
    padding: 0;
    counter-reset: item;
  }

  a {
    border-bottom: 1px dotted;
  }

  img {
    @media only screen and (max-width: $tabletBreakPoint) {
      max-width: 100%;
      height: auto;
    }
  }

  figure {

    padding: $colPadding/2;
    background: $cream;
    display: table;
    margin-bottom: $colPadding*2;

    .striped & {
      background: $white;
    }


    &.centered {
      margin: 0 auto;
      margin-top: $colPadding*2;
      margin-bottom: $colPadding*2;
    }

    &.floating-left {
      float: left;
      margin-right: $colPadding;
      margin-top: 0;
      overflow: auto;

      &:after {
        content: ".";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
      }
    }

    &.floating-right {
      float: right;
      margin-right: $colPadding;
      margin-top: 0;

      &:after {
        clear: both;
      }
    }

    figcaption {
      background: $primaryColor;
      color: $white;
      padding: $colPadding/2;

      em {
        display: block;
      }
    }
  }

  h3 {
    color: $primaryColor;
  }

  .highlighted {
    background: $orange;
    color: $white;
    @include condensedFont;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 5px 10px;
    display: inline-block;
    margin-bottom: $colPadding;

    &.with-icon {
      text-transform: none;
      display: block;

      &:before {
        @extend .iconChart;
        margin-right: 5px;
        position: relative;
        top: 1px;
      }
    }
  }

  .image-with-caption {

    font: 0/0;

    img {
      max-width: 100%;
      height: auto;
      background: $white;
    }

    br {
      display: none;
    }

    figcaption {
      background: transparent;
      color: $darkGrey;
      @include titleFontItalic;
      margin: 0;
      padding: 5px 0;
      font-size: 1rem;
    }

  }

  ul.links {

    li {
      list-style: none;
      @include condensedFont;
      font-size: 1.2rem;
      margin-bottom: $colPadding/4;

      a {
        color: $darkGrey;
        @include transition(all 300ms ease-in-out);

        &:hover {
          color: $primaryColor;
        }

      }
    }


    &.twitter {

      li {
        position: relative;
        margin-left: 1.5em;

        &:before {
          @extend .iconTwitter;
          position: absolute;
          left: -1.5em;
          top: 50%;
          @include transform(translateY(-50%));
          color: $colorTwitter;
        }
      }
    }

  }

  .highlighted-box {
    display: block;
    background: $orange;
    padding: $colPadding;

    h3 {
      @include titleFontBold;
      text-transform: uppercase;
      color: $white !important;
      text-align: center;
      margin: 0 0 $colPadding/2 0 !important;
      padding: 0 0 $colPadding/2 0;
      border-bottom: 1px dotted $white;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        display: inline-block;
        margin-right: $colPadding/4;
        padding-right: $colPadding/4;
        border-right: 1px solid $white;

        @media only screen and (max-width: $tabletBreakPoint) {
          display: block;
          margin-right: 0;
          padding-right: 0;
          border-right: none;
        }

        &:last-child {
          margin:0;
          border:none;
        }

        a {
          display: inline-block;
          color: $white;
          border: none;
          padding: 5px 10px;

          &:hover {
            background: $yellow;
            color: $black;
          }
        }
      }
    }

  }

  .download-button {

    a  {

      position: relative;
      @include condensedFontBold;
      color: $white;
      border: none;
      font-size: 2rem;
      background: $orange;
      padding: $colPadding/2 $colPadding $colPadding/2 $colPadding*4;
      display: inline-block;
      margin: $colPadding 0;

      &:before {
        @extend .iconDownload;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        font-size: 48px;
        background: $yellow;
        padding: 5px;
        @include transition(all 300ms ease-in-out);
      }

      span {
        display:inline-block;
      }

      &:hover {
        background: $yellow;
        color: $black;

        &:before {
          background: $orange;
          color: $white;
        }
      }


    }
  }

  em {
    @include textFontItalic;
  }

}
